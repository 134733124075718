<div class="container">
  <div *ngIf="!registrationOK">
    <div class="row">
      <div
        class="alert alert-info col-md-5">{{ schemaService.getLabel('local_auth_reg_email_prompt', 'Saisissez votre adresse email') }}
      </div>
    </div>
    <div class="row">
      <div class="form-group col-md-5">
        <label for="inputLogin">Email</label>
        <input type="email" class="form-control" id="inputLogin" placeholder="Login" [(ngModel)]="inpLogin"/>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-md-10">
        <label for="contact">
          <input class="mr-3" type="checkbox" id="contact" name="contact" [(ngModel)]="isContactChecked">
          I agree to be contacted by ISAE-Supaero about my application (otherwise you will not be able to continue your application)
        </label>
      </div>
    </div>
    <div class="row">
      <button [disabled]="!isContactChecked" *ngIf="newUser" class="btn btn-default"
              (click)="registerUser(inpLogin)">{{ schemaService.getLabel('local_auth_reg_submit', 'Soumettre') }}
      </button>
      <button [disabled]="!isContactChecked" *ngIf="!newUser" class="btn btn-default"
              (click)="askResetUserPwdLink(inpLogin)">{{ schemaService.getLabel('local_auth_reg_submit', 'Soumettre') }}
      </button>
    </div>
  </div>
  <br/>
  <div *ngIf="registrationNOK" class="col-md-5 alert alert-danger">
    <p>{{ message }}</p>
    <p>
      {{ schemaService.getLabel('local_auth_reg_nok', "Il y a eu un probl&egrave;me lors de l'enregistrement. Merci de renouveler votre demande.") }}
    </p>
  </div>
  <div *ngIf="registrationOK" class="col-md-5 alert alert-success">
    {{ schemaService.getLabel('local_auth_reg_ok', "Votre demande a été enregistrée. Merci de cliquer sur le lien dans l'email qui vous a été envoyé.") }}
  </div>
</div>
