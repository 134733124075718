<nav class="navbar navbar-fixed-top navbar-inverse">
  <div class="container">
    <div class="navbar-header">
      <button type="button" class="navbar-toggle collapsed" data-toggle="collapse" data-target="#navbar"
              aria-expanded="false" aria-controls="navbar">
        <span class="sr-only">Toggle navigation</span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
      </button>
      <a class="navbar-brand" href="http://www.isae-supaero.fr/"><img src='../assets/images/Logo-ISAE.png' BORDER=0
                                                                      width=80 height=35></a>
    </div>
    <div id="navbar" class="collapse navbar-collapse">
      <ul class="nav navbar-nav">
        <li><a routerLink="/"><b>Home</b></a></li>
        <li><a href="mailto:info-programmes@isae-supaero.fr"><b>Contact</b></a></li>
        <li *ngIf="!auth.isLoggedIn"><a routerLink='/form/candform'><b>Login</b></a></li>
        <li *ngIf="auth.isLoggedIn"><a routerLink='/' (click)="auth.logout()"><b>Logout</b></a></li>
      </ul>
      <ul class="nav navbar-nav navbar-right">
        <li><p class="navbar-text">{{ this.schemaService.authservice.user?.login }}</p></li>
      </ul>
    </div><!-- /.nav-collapse -->
  </div><!-- /.container -->
</nav><!-- /.navbar -->
<router-outlet></router-outlet>
