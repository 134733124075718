import {Component} from '@angular/core';

@Component({
  template: `
    <H2>NOT FOUND</H2>
  `
})
export class PageNotFoundComponent {

}
