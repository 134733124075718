import {switchMap} from 'rxjs/operators';
import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';


import {Validator} from '../angular2-schema-form';
import {FormSchemaService} from './form-schema.service';
import {AuthService} from './auth/auth.service';
import {IFormConfService} from './iformconf.service';

@Component({
  template: `
    <div class="container-fluid">
      <div class="row">
        <!-- admin menu -->
        <!--<a (click)="loadAnswer('b.ilponse')"> test de chargement (pour vérifier la bonne gestion des droits) </a>-->
        <div *ngIf="aus.isAdmin" class="col-md-3">
          <tabset>
            <tab *ngFor="let tab of confService.adminConfig.tabs" [heading]="tab.title + ' (' + answersCountForTab(tab) + ')'">
              <ul class="nav nav-pills nav-stacked">
                <ng-container *ngFor="let answer of answers">
                  <li *ngIf="tab.filter(answer)" [class.active]="schemaService.answerid==answer['asw_userid']">
                    <a (click)="loadAnswer(answer['asw_userid'])"> {{ tab.labeler(answer) }} </a>
                  </li>
                </ng-container>
              </ul>
            </tab>
          </tabset>
        </div>
        <div [class.col-sd-12]="!aus.isAdmin" [class.col-md-9]="aus.isAdmin">
          <!-- form -->
          <div class="container iform-form-container" style="padding: 1em;">
            <div *ngIf="!loaded" class="centered"><h1><i class="fa fa-spinner fa-spin"></i></h1></div>
            <sf-form *ngIf="loaded" [schema]="schema" [actions]="actions" [options]="options" [model]="model" [validators]="fieldValidators"
                     (onChange)="onChanges($event)"
                     (onInit)="onInit($event)"></sf-form>
          </div>
          <!-- messages -->
          <div *ngIf="schemaService.filterMessages('danger').length>0" class="alert alert-danger errors" role="alert">
            <button type="button" class="close" (click)="schemaService.clearMessages()">
              <span aria-hidden="true">&times;</span>
            </button>
            <span *ngFor="let message of schemaService.messages"><span *ngIf="message.type==='danger'">{{ message.value }}
              <br/></span></span>
          </div>
          <div *ngIf="schemaService.filterMessages('success').length>0" class="alert alert-success errors" role="alert">
            <button type="button" class="close" (click)="schemaService.clearMessages()">
              <span aria-hidden="true">&times;</span>
            </button>
            <span *ngFor="let message of schemaService.messages"><span *ngIf="message.type==='success'">{{ message.value }}
              <br/></span></span>
          </div>
        </div>
      </div>
    </div>
  `,
  styles: ['.centered { position: fixed;top: 50%;left: 50%;transform: translate(-50%, -50%); }',
    '.errors { position: fixed;top: 100px; right: 10px; }']
})

export class FormDetailsComponent implements OnInit {
  public schema: {};
  public model: {};
  // list of user answers (for admin panel)
  public answers: Array<Object>;
  private _actions: {} = {};
  private _fieldValidators: { [fieldId: string]: Validator } = {};
  public options: {} = null;
  public loaded = false;
  // A saved verion of the values passed to onChanges
  private savedValue: any = {};

  constructor(public schemaService: FormSchemaService, private route: ActivatedRoute,
              private router: Router, public aus: AuthService, public confService: IFormConfService) {
    this.confService.formCompo = this;
    this._actions = confService.actions;
    this._fieldValidators = confService.fieldValidators;
    this.answers = [];
    // permet de recharger le schéma si cela est demandé par l'applicatif
    schemaService.clearMessages();
    schemaService.answerModified.subscribe((event) => {
      // rechargement du schéma
      this.schemaService.getFormAnswers(schemaService.answerid).then((model: any) => this.setModel(model));
      // rechargement de la liste des réponses
      this.schemaService.listAnswers(this.confService.adminConfig.listed_answers_fields).then((answers: any) => this.answers = answers);
    });
    this.schemaService.modelModified.subscribe((model) => {
      this.setModel(model, false);
    });
    this.schemaService.modifiedSchema.subscribe((schema) => {
      this.schema = schema;
    });

  }


  ngOnInit(): void {
    // lecture de la liste des réponses (admin)
    if (this.aus.isAdmin) {
      this.route.params.pipe(
        switchMap((params: Params) => {
          return this.schemaService.listAnswers(this.confService.adminConfig.listed_answers_fields);
        }))
        .subscribe((answers: any) => this.answers = answers,
          (error: any) => console.log(error));
    }
    // lecture de la réponse si paramètre d'user_id
    this.route.params.pipe(
      switchMap((params: Params) => {
        return this.schemaService.getFormAnswers(params['user_id']);
      }))
      .subscribe((model: any) => this.setModel(model), (error: any) => console.log(error));

    this.route.params.pipe(
      switchMap(() => this.schemaService.getJSONFormSchema()))
      .subscribe((schema: any) => {
        if (schema.result !== undefined && schema.result === 'Not allowed') {
          this.aus.isLoggedIn = false;
          this.aus.user = {};
          this.aus.user.login = null;
          this.aus.user.token = null;
          this.router.navigate(['/login', {id: this.schemaService.formid}]);
        } else {
          this.schema = schema;
          this.loaded = true;
          this.loadCallback();
        }
      }, (error: any) => console.log(error));

    this.route.params.subscribe((params: Params) => {
      this.updateSchemaOptions();
    }, (error: any) => console.log(error));

  }

  updateSchemaOptions() {
    const removeFonc = (file) => {
      return this.schemaService.rmUploadedFile(file);
    };
    const downloadFonc = (file) => {
      return this.schemaService.downloadUploadedFile(file);
    };
    this.options = {
      uploadService: this.schemaService.wsurl + '/' + this.schemaService.formid
        + '/answer/' + this.schemaService.answerid + '/file',
      authorHeader: {name: 'X-isae-authz', value: this.aus.user.token}
      , rmFct: removeFonc
      , dlFct: downloadFonc
      , adminMode: this.aus.isAdmin > 0
      , autoUpload: this.confService.fileAutoUpload
    };
    Object.assign(this.options, this.schemaService.options);
  }

  setModel(model, callback = true) {
    this.model = model;
    this.updateSchemaOptions();
    if (callback) {
      this.loadCallback();
    }
  }

  getModel() {
    return this.model;
  }

  loadAnswer(user) {
    this.schemaService.getFormAnswers(user)
      .then((model: any) => {
        this.setModel(model);
      }, (error: any) => console.log(error));
  }

  loadCallback() {
    if (this.model !== undefined && this.schema !== undefined) {
      this.confService.onLoadCallback.call(this, this.model, this.schema);
    }
  }

  answersCountForTab(tab) {
    let count = 0;
    for (const i in this.answers) {
      if (tab.filter(this.answers[i])) {
        count++;
      }
    }
    return count;
  }

  get actions(): {} {
    return this._actions;
  }

  set actions(myActions: {}) {
    this._actions = myActions;
  }

  get fieldValidators(): { [fieldId: string]: Validator } {
    return this._fieldValidators;
  }

  set fieldValidators(myFieldValidators: { [fieldId: string]: Validator }) {
    this._fieldValidators = myFieldValidators;
  }

  normalize(value) {
    let localValue = value;

    if (localValue === undefined || localValue === null || localValue === '' || localValue === 0) {
      localValue = null;
    }
    return localValue;
  }

  onChanges(event) {
    // Checking changes
    const valueChanges = {};
    const value = event.value;

    for (const key in value) {
      // normalisation
      const localValue = this.normalize(value[key]);
      const savedValue = this.normalize(this.savedValue[key]);
      if ((localValue !== savedValue && localValue !== null)) {
        valueChanges[key] = true;
        this.savedValue[key] = localValue;
      }
    }
    // Putting changes in event
    event.changes = valueChanges;
    // Putting model in event
    event.schema = this.schema;

    // Saving values
    // this.savedValue = Object.assign({}, value)

    // Notifying only if atomic change
    if (Object.keys(event.changes).length === 1) {
      this.schemaService.valueModified.next(event);
    }
  }

  onInit(event) {
    this.schemaService.formInitialized.next(event);
  }

}
