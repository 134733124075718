import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {HomeComponent} from './home.component';
import {PageNotFoundComponent} from './page-not-found.component';
import {FormDetailsComponent} from './iform/form-details.component';
import {AuthGuard} from './iform/auth/auth-guard.service';

const appRoutes: Routes = [
  {path: 'form/:id', component: FormDetailsComponent, canActivate: [AuthGuard]},
  {path: '', component: HomeComponent},
  {path: '**', component: PageNotFoundComponent}
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule {
}
