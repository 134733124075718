import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {LoginComponent} from './login.component';
import {LoginRoutingModule} from './login-routing.module';
import {LocalRegistrationComponent} from './local-registration.component';
import {LocalActivationComponent} from './local-activation.component';

@NgModule({
  imports: [FormsModule,
    CommonModule,
    LoginRoutingModule
  ],
  declarations: [
    LoginComponent,
    LocalRegistrationComponent,
    LocalActivationComponent
  ]
})
export class AuthModule {
}
