import {Component, Input, OnInit} from '@angular/core';
import {Observable} from 'rxjs';

import {AuthService} from './auth.service';
import {FormSchemaService} from '../form-schema.service';

@Component({
  selector: 'local-registration',
  templateUrl: './local-registration.component.html'
})
export class LocalRegistrationComponent implements OnInit {
  @Input()
  newUser: boolean = true;

  inpLogin: string = '';
  registrationOK: boolean = false;
  registrationNOK: boolean = false;
  message: string = '';

  isContactChecked: boolean = false;
  
  constructor(private authServ: AuthService, public schemaService: FormSchemaService) {
  }

  ngOnInit(): void {

  }

  registerUser(login: string) {
    if (login != undefined && login != '') {
      let retour: Observable<any> = this.authServ.registerUser(login);
      retour.subscribe(response => {
        let dt = response;
        if (dt.result == 'ok') {
          this.registrationOK = true;
          this.registrationNOK = false;
        } else if (dt.result == 'error') {
          this.registrationOK = false;
          this.registrationNOK = true;
          if (dt.message == 'USER_IS_ACTIVATED') {
            this.message = 'This account is already registered.';
          }
        }
      });
    }
  }

  askResetUserPwdLink(login: string) {
    if (login != undefined && login != '') {
      let retour: Observable<any> = this.authServ.askResetPwdLink(login);
      retour.subscribe((response: any) => {
        let dt = response;
        if (dt.result == 'ok') {
          this.registrationOK = true;
          this.registrationNOK = false;
        } else if (dt.result == 'error') {
          this.registrationOK = false;
          this.registrationNOK = true;
          if (dt.message == 'USER_IS_NOT_ACTIVATED') {
            this.message = 'This account does not exists / Ce compte n\'existe pas.';
          }
        }
      });
    }
  }

}
