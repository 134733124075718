import {Injectable} from '@angular/core';
import {FormSchemaService} from './form-schema.service';
import {AuthService} from './auth/auth.service';
import {Validator} from '../angular2-schema-form';
import {FormDetailsComponent} from './form-details.component';

@Injectable()
export class IFormConfService {
  private _fdc: FormDetailsComponent = null;
  private _wsurl: string = null;
  private _formid: string = null;
  private _baseurl: string = null;
  private _webappurl: string = null;
  private _actions: {} = {};
  private _fieldValidators: { [fieldId: string]: Validator } = {};
  // une fonction qui s'exécute au chargement d'un formulaire dans le contexte du FormDetailsComponent
  private _onLoadCallback = (model, schema) => undefined;

  // Configuration de l'onglet d'administration
  private _adminConfig = {
    // tabs: onglets à afficher )
    'tabs': [
      // title: titre de l'onglet
      {
        'title': 'Toutes les réponses'
        // filter: fonction qui filtre les réponses à afficher dans cet onglet
        , 'filter': (answer) => true
        // labeler: fonction qui donne un label à une answer
        , 'labeler': (answer) => answer.asw_userid
        // (on pourrait rajouter: ordre du tri/fonction pour affichage du label - au lieu du userid - ...)
      }
    ]
    // champs à récupérer des answers
    , 'listed_answers_fields': 'asw_last_modified,asw_created',
  };

  private _fileAutoUpload: boolean = false;


  constructor(private _formservice: FormSchemaService, private _authservice: AuthService) {
  }

  get formid(): string {
    return this._formid;
  }

  set formid(myFormid: string) {
    this._formid = myFormid;
    this._formservice.formid = this._formid;
    this._authservice.formid = this._formid;
  }

  get wsurl(): string {
    return this._wsurl;
  }

  set wsurl(myServerws: string) {
    this._wsurl = myServerws;
    this._formservice.wsurl = this._wsurl;
    this._authservice.wsurl = this._wsurl;
  }

  get baseurl(): string {
    return this._wsurl;
  }

  set baseurl(myServerbase: string) {
    this._baseurl = myServerbase;
    this._authservice.baseurl = this._baseurl;
  }

  get webappurl(): string {
    return this._webappurl;
  }

  set webappurl(webappurl: string) {
    this._webappurl = webappurl;
    this._authservice.webappurl = this.webappurl;
  }

  get actions(): {} {
    return this._actions;
  }

  set actions(myActions: {}) {
    this._actions = myActions;
  }

  get formservice(): FormSchemaService {
    return this._formservice;
  }

  get authservice(): AuthService {
    return this._authservice;
  }

  get fieldValidators(): { [fieldId: string]: Validator } {
    return this._fieldValidators;
  }

  set fieldValidators(myFieldValidators: { [fieldId: string]: Validator }) {
    this._fieldValidators = myFieldValidators;
  }

  get adminConfig() {
    return this._adminConfig;
  }

  set adminConfig(adminConfig) {
    this._adminConfig = adminConfig;
  }

  get onLoadCallback() {
    return this._onLoadCallback;
  }

  set onLoadCallback(onLoadCallback) {
    this._onLoadCallback = onLoadCallback;
  }

  get formCompo() {
    return this._fdc;
  }

  set formCompo(fdc: FormDetailsComponent) {
    this._fdc = fdc;
  }

  get fileAutoUpload() {
    return this._fileAutoUpload;
  }

  set fileAutoUpload(autoUpload: boolean) {
    this._fileAutoUpload = autoUpload;
  }
}
