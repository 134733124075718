import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {AuthModule} from './auth/auth.module';
import {SchemaFormModule} from '../angular2-schema-form';

import {FormDetailsComponent} from './form-details.component';
import {FormSchemaService} from './form-schema.service';
import {TabsModule} from 'ngx-bootstrap/tabs';
import {IFormConfService} from './iformconf.service';

@NgModule({
  imports: [CommonModule, SchemaFormModule, AuthModule, TabsModule],
  declarations: [FormDetailsComponent],
  providers: [FormSchemaService, IFormConfService]
})
export class IFormModule {
}
