import {Injectable} from '@angular/core';
import {AuthType} from './auth/auth-provider-factory';
import {Observable, Subject} from 'rxjs';

import {AuthService} from './auth/auth.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';

export class FormSchemaMessage {
  public type: string;
  public value: string;

  constructor(type: string, value: string) {
    this.type = type;
    this.value = value;
  }
}

@Injectable()
export class FormSchemaService {

  // Permet au composant de prévenir le formdetail component que l'ont veut recharger l'answer
  public answerModified: Subject<any> = new Subject();
  // Permet au composant de prévenir le formdetail component que l'application à modifié le model
  public modelModified: Subject<any> = new Subject();
  // Permet au composant de demander au formdetail component de recharger le schéma
  public modifiedSchema: Subject<any> = new Subject();
  // Permet au formdetail component de prévenir l'application qu'une valeur à changé
  public valueModified: Subject<any> = new Subject();
  // Permet deprévenir que le schéma a été chargé
  public schemaLoaded: Subject<any> = new Subject();
  // Permet deprévenir que le scéma et le model ont été chargés
  public formInitialized: Subject<any> = new Subject();
  // Options à passer à angularSchemaForm
  public options: any = {};
  private _labels: any = null;

  constructor(private http: HttpClient, private as: AuthService) {
  }

  private _wsurl: string = null;

  get wsurl(): string {
    return this._wsurl;
  }

  set wsurl(myServerws: string) {
    this._wsurl = myServerws;
  }

  private _baseurl: string = null;

  get baseurl(): string {
    return this._baseurl;
  }

  set baseurl(myServerbase: string) {
    this._baseurl = myServerbase;
  }

  private _formid: string = null;

  get formid(): string {
    return this._formid;
  }

  set formid(myFormid: string) {
    this._formid = myFormid;
    this.getSchemaLabels();
  }

  private _answerid: string = null;

  get answerid() {
    return this._answerid;
  }

  set answerid(answer_id) {
    this._answerid = answer_id;
  }

  private _messages: Array<FormSchemaMessage> = [];

  get messages() {
    return this._messages;
  }

  private static toAuthType([authType]: [string]): AuthType {
    let ret: AuthType;
    switch (authType) {
    case 'CAS':
    case 'cas':
      ret = AuthType.cas;
      break;
    case 'local':
      ret = AuthType.local;
      break;
    }
    return ret;
  }

  getJSONFormSchema(): Promise<any> {
    let url: string = this.wsurl + '/' + this._formid + '/schema';
    let headers = new HttpHeaders({'Content-Type': 'application/json', 'X-isae-authz': [this.as.user.token]});
    let options: any = {headers};
    return this.http.get(url, options)
      .toPromise()
      .then(response => {
        let schema = response as any;
        this.schemaLoaded.next(schema);
        return schema;
      });
  }

  // récupère les labels du schéma en cours
  getSchemaLabels(): Promise<any> {
    let url: string = this.wsurl + '/' + this._formid + '/labels';
    return this.http.get(url)
      .toPromise()
      .then(response => {
        this._labels = response['labels'];
        return this._labels;
      });
  }

  getLabel(key: string, defo: string) {
    if (this._labels != null) {
      let label = this._labels[key];
      return label !== undefined ? label : defo;
    }
    return defo;
  }

  // change currently displayed answers
  answerForm(data: string): Observable<any> {
    let headers = new HttpHeaders({'Content-Type': 'application/json', 'X-isae-authz': [this.as.user.token]});
    let options: any = {headers};
    return this.http.post(this.wsurl + '/' + this._formid + '/answer/' + this._answerid, data, options);
  }

  // valider une étape du formulaire affiché
  validateStep(step): Promise<any> {
    const url = this.wsurl + '/' + this._formid + '/answer/' + this._answerid + '/step/' + step;
    let headers = new HttpHeaders({'Content-Type': 'application/json', 'X-isae-authz': [this.as.user.token]});
    return this.http.put(url, null, {headers}).toPromise();
  }

  // get user answers
  getFormAnswers(answerid?): Promise<any> {
    if (answerid === undefined) {
      answerid = this.as.user.login;
    }

    let url: string = this.wsurl + '/' + this._formid + '/answer/' + answerid;
    let headers = new HttpHeaders({'Content-Type': 'application/json', 'X-isae-authz': [this.as.user.token]});
    let options: any = {headers};
    return this.http.get(url, options)
      .toPromise().then(response => {
        this.answerid = answerid;
        return response;
      });
  }

  getFormAuthTypes(): Promise<AuthType> {
    let url: string = this.wsurl + '/' + this._formid + '/authtypes';
    return this.http.get(url)
      .toPromise()
      .then(response => FormSchemaService.toAuthType(response['authType']));
  }

  rmUploadedFile(fileName: string): Promise<any> {
    let url: string = this.wsurl + '/' + this._formid + '/answer/' + this._answerid + '/file/' + fileName;
    let headers = new HttpHeaders({'Content-Type': 'application/json', 'X-isae-authz': [this.as.user.token]});
    let options: any = {headers};
    return this.http.delete(url, options)
      .toPromise()
      .then(response => response as any);
  }

  downloadUploadedFile(fileName: string): Promise<any> {
    let url: string = this.wsurl + '/' + this._formid + '/answer/' + this._answerid + '/file/' + fileName;
    let headers = new HttpHeaders({'Content-Type': 'application/json', 'X-isae-authz': [this.as.user.token]});
    let options: any = {headers, responseType: 'blob'};
    return this.http.get(url, options)
      .toPromise()
      .then(response => response);
  }

  // list form answers
  listAnswers(fields): Promise<any> {
    let url: string = this.wsurl + '/' + this._formid + '/answers';
    if (fields !== undefined) {
      url = url + '?fields=' + fields;
    }

    let headers = new HttpHeaders({'Content-Type': 'application/json', 'X-isae-authz': [this.as.user.token]});
    let options: any = {headers};
    return this.http.get(url, options)
      .toPromise()
      .then(response => response as any);
  }

  addMessage(type, value) {
    this._messages.push(new FormSchemaMessage(type, value));
  }

  removeMessage(message) {
    this.messages.splice(this.messages.indexOf(message), 1);
  }

  clearMessages() {
    this._messages = [];
  }

  filterMessages(type) {
    return this.messages.filter((message) => message.type === type);
  }
}
