<div class="container">
  <div *ngIf="!activationOK">
    <div class="row">
      <div
        class="alert alert-info col-md-6">{{ schemaService.getLabel('local_auth_password_prompt', 'Choix du mot de passe') }}
      </div>
    </div>
    <div class="row">
      <div class="form-group col-md-6">
        <label for="inputPassword">Password</label>
        <input type="password" class="form-control" id="inputPassword" placeholder="Password"
               [(ngModel)]="inpPassword"/>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-md-6">
        <label
          for="inputPasswordConfim">{{ schemaService.getLabel('local_auth_confirm_password_prompt', 'Confirmation du password') }}</label>
        <input type="password" class="form-control" id="inputPasswordConfim" placeholder="Password"
               [(ngModel)]="inpPasswordConf"/>
      </div>
    </div>
    <div class="row">
      <div *ngIf="inpPassword != '' && !isPasswordsEquals()" class="col-md-6 alert alert-warning">
        {{ schemaService.getLabel('local_auth_password_diff', 'Les mots de passe ne sont pas identiques.') }}
      </div>
      <div *ngIf="isPasswordsEquals() && !isPasswordValid()" class="col-md-6 alert alert-warning">
        {{ schemaService.getLabel('local_auth_password_invalid', 'Le mot de passe doit contenir au moins 12 caractères.') }}
      </div>
    </div>
    <div class="row">
      <button *ngIf="!resetPassword" class="btn btn-default"
              (click)="activateUser()">{{ schemaService.getLabel('local_auth_password_submit', 'Activer le compte') }}
      </button>
      <button *ngIf="resetPassword" class="btn btn-default"
              (click)="resetUser()">{{ schemaService.getLabel('local_auth_password_submit', 'Activer le compte') }}
      </button>
    </div>
    <br/>
    <div *ngIf="activationNOK" class="row alert alert-danger col-md-6">
      {{ schemaService.getLabel('local_auth_password_nok', "Il y a eu un probl&egrave;me lors de l'activation de votre compte.") }}
    </div>
  </div>
  <div *ngIf="activationOK" class="row alert alert-success col-md-6">
    {{ schemaService.getLabel('local_auth_password_ok', 'Votre compte a été activé.') }}

  </div>

</div>
