import {Component, OnInit} from '@angular/core';
import {IFormConfService} from './iform/iformconf.service';
import {BackofficeService} from './backoffice.service';
import {AuthService} from './iform/auth/auth.service';
import {DefaultWidgetRegistry, WidgetRegistry} from './angular2-schema-form';

import {SchemaUtils} from './iform/utils/schema-utils';
// import {Config} from './app.config';
import {environment} from '../environments/environment';


import '../assets/css/style2.css';
import '../assets/css/theme.css';
import '@ng-select/ng-select/themes/default.theme.css';

import {Router} from '@angular/router';
import {ArrayProperty, ObjectProperty} from './angular2-schema-form/model';
import {HttpResponse} from '@angular/common/http';
import {FormSchemaService} from './iform/form-schema.service';
import {TabService} from './services/tab.service';


@Component({
  selector: 'my-app',
  templateUrl: './app.component.html',
  providers: [{provide: WidgetRegistry, useClass: DefaultWidgetRegistry}]
})
export class AppComponent implements OnInit {

  constructor(
    public schemaService: IFormConfService,
    private bos: BackofficeService,
    public auth: AuthService,
    private tabService: TabService,
    protected scService: FormSchemaService,
    private router: Router) {
  }
  private countries: any[] = [];
  private jurys: any[] = [];
  private formations: any[] = [];
  private formationsPgdip: any[] = [];
  private readonly english_speaking_countries: string[] = ['GB', 'US', 'IE', 'CA', 'ZA', 'NZ', 'AU'];
  private paymenturl: string;
  private montant: number;

  private static getFileType(schema) {
    const formation = schema.properties['typeformation'].value;
    // info bourse prioritaire
    if (schema.properties['bourses_cedar'].value === 'Y'
      || schema.properties['bourses_gifas'].value === 'Y'
      || schema.properties['bourses_mbda'].value === 'Y'
      || schema.properties['bourses_isae'].value === 'Y'
      || schema.properties['bourses_tsae'].value === 'Y'
      || schema.properties['bourses_arise'].value === 'Y'
      || schema.properties['bourses_tuition'].value === 'Y'
    ) {
      return 'BOURSE';
    }
    if (formation === 'MSC') {
      return 'MSc';
    }
    if (formation === 'AM') { // TODO à confirmer lors de la démo
      return 'MS';
    }
    if (formation === 'PG_DIP') {
      return 'PGDiP';
    }
  }

  private sessionExpired() {
    this.router.navigate(['/login', {id: 'candform', message: 'Session expired, please login again.'}]);
  }

  /**
   * Remove docs entries with no type or no file.
   */
  private cleanupDocsProperty(property: any) {
    const docsProp = property.properties.docs as ArrayProperty;
    const docItems = (docsProp.properties as ObjectProperty[]);
    const filteredDocItems = docItems.filter(d => d.properties['doc_file'].value && d.properties['type_doc'].value);
    if (docItems.length > filteredDocItems.length) {
      docsProp.properties = filteredDocItems;
      docsProp.updateValueAndValidity(false, true);
      this.schemaService.formservice.addMessage('danger', 'Removed invalid doc entries');
      return false;
    }
    return true;
  }

  private checkTabValidation(property: any): boolean {
    return property.properties['choice_validated'].value === 'O'
      && property.properties['personal_validated'].value === 'O'
      && property.properties['background_validated'].value === 'O'
      && property.properties['langs_validated'].value === 'O'
      && property.properties['fund_validated'].value === 'O'
      && property.properties['docs_validated'].value === 'O';
  }

  private checkTabRegister(property: any): boolean {
    return property.properties['registration_validated'].value === 'O'
      && property.properties['funding2_validated'].value === 'O'
      && property.properties['parents_validated'].value === 'O'
      && property.properties['siblings_validated'].value === 'O'
      && property.properties['address_validated'].value === 'O'
      && property.properties['documents2_validated'].value === 'O';
  }

  private handleTabValidationError(property: any) {
    const tabToCheck: string[] = ['choice', 'personal', 'background', 'langs', 'fund', 'docs'];
    for (const tab of tabToCheck) {
      if (property.properties[tab + '_validated'].value !== 'O') {
        this.schemaService.formservice.addMessage('danger', tab + ' tab is not validated');
      }
    }
  }

  private handleValidationError(property, tabId) {
    const fs = SchemaUtils.getFieldsetsInTab(tabId, property.schema);
    for (const localFs of fs) {
      for (const field of localFs.fields) {
        if (property.properties[field].submitable && property.properties[field].visible && !property.properties[field].valid) {
          if (property.properties[field].schema.type !== 'array') {
            const lib = property.properties[field].schema.title !== undefined
            && property.properties[field].schema.title !== null
            && property.properties[field].schema.title !== ''
              ? property.properties[field].schema.title
              : property.properties[field].schema.description;
            this.schemaService.formservice.addMessage('danger', lib + ' is not completed properly');
          }
        }
      }
    }
  }

  private areTabsPreviouslyValidated(property: any, tabs: string[]) {
    let result = false;
    for (const tab of property.schema.tabs) {
      if (tabs.indexOf(tab) !== -1) {
        if (property.properties[tab + '_validated'] && property.properties[tab + '_validated'] === 'O') {
          result = true;
        }
      }
    }
    return result;
  }

  private handleValidationErrorAllTabs(property) {
    const tabToCheck = ['choice', 'personal', 'background', 'langs', 'fund', 'docs', 'validation'];
    // si les premiers onglets ne sont pas validés, alors on ne vérifie que ceux-cis
    if (this.areTabsPreviouslyValidated(property, tabToCheck)) {
      for (const tab of property.schema.tabs) {
        this.handleValidationError(property, tab.id);
      }
    } else {
      for (const tab of tabToCheck) {
        this.handleValidationError(property, tab);
      }
    }
  }

  private needLangDoc(typeform: string, fstch: string, sndch: string, trdch: string, nationality: string, dbl_nationality: string): boolean {
    let result = true;
    const frenchAM: string[] = ['MS SAS', 'MS SPA'];
    if (this.isEnglishSpeakingNationality(nationality, dbl_nationality)) {
      if (typeform !== 'ING'
        && (typeform !== 'AM'
          || !(frenchAM.indexOf(fstch) > -1
            || frenchAM.indexOf(sndch) > -1
            || frenchAM.indexOf(trdch) > -1))
      ) {
        result = false;
      }
    }
    if (typeform === 'ING') {
      result = false;
    }
    return result;
  }

  private isEnglishSpeakingNationality(nationality: string, dbl_nationality: string): boolean {
    return this.english_speaking_countries.indexOf(nationality) > -1
      || this.english_speaking_countries.indexOf(dbl_nationality) > -1;
  }

  private checkListBourses(property) {
    // liste des bourses auxquelles le candidat a postulé
    const list_of_bourses: string[] = [];
    if (property.properties['bourses_cedar'].value === 'Y'
      && (this.hasCedar(
        property.properties['typeformation'].value,
        property.properties['mscchoice'].value))
    ) {
      list_of_bourses.push('cedar');
    }
    if (property.properties['bourses_gifas'].value === 'Y'
      && (this.hasGifas(
        property.properties['typeformation'].value,
        property.properties['is_european'].value,
        property.properties['mscchoice'].value))
    ) {
      list_of_bourses.push('gifas');
    }
    if (property.properties['bourses_isae'].value === 'Y'
      && (this.hasIsae(
        property.properties['typeformation'].value,
        property.properties['nationality'].value,
        property.properties['dual_nationality'].value,
        property.properties['mscchoice'].value))
    ) {
      list_of_bourses.push('isae');
    }
    if (property.properties['bourses_tsae'].value === 'Y'
      && (this.hasTsae(
        property.properties['typeformation'].value,
        property.properties['is_european'].value,
        property.properties['mscchoice'].value))
    ) {
      list_of_bourses.push('tsae');
    }

    if (property.properties['bourses_dga'].value === 'Y'
      && (this.hasDga(
        property.properties['typeformation'].value,
        property.properties['mscchoice'].value))
    ) {
      list_of_bourses.push('dga');
    }

    if (property.properties['bourses_iem'].value === 'Y'
      && (this.hasIem(
        property.properties['mscchoice'].value,
        property.properties['typeformation'].value,
        property.properties['fstchoice'].value,
        property.properties['sndchoice'].value,
        property.properties['trdchoice'].value))
    ) {
      list_of_bourses.push('iem');
    }

    if (property.properties['bourses_isae_excel_schol'].value === 'Y'
      && (this.hasIsaeExcelSchol(property.properties['typeformation'].value,property.properties['is_european'].value,
        property.properties['mscchoice'].value))
    ) {
      list_of_bourses.push('isae_excel_schol');
    }
    return (list_of_bourses);
  }

  private checkDocsBourses(property) {
    // liste des documents bourses à founir
    const needed_type_doc_bourses: string[] = [];
    // liste des documents bourses absents
    const empty_doc_bourses: string[] = [];
    // calcul liste des documents bourses à founir
    if (property.properties['bourses_cedar'].value === 'Y'
      && (this.hasCedar(
        property.properties['typeformation'].value,
        property.properties['mscchoice'].value))
    ) {
      needed_type_doc_bourses.push('file_cedar');
    }
    if (property.properties['bourses_gifas'].value === 'Y'
      && (this.hasGifas(
        property.properties['typeformation'].value,
        property.properties['is_european'].value,
        property.properties['mscchoice'].value))
    ) {
      needed_type_doc_bourses.push('file_gifas');
    }
    if (property.properties['bourses_isae'].value === 'Y'
      && (this.hasIsae(
        property.properties['typeformation'].value,
        property.properties['nationality'].value,
        property.properties['dual_nationality'].value,
        property.properties['mscchoice'].value))
    ) {
      needed_type_doc_bourses.push('file_isae');
    }
    if (property.properties['bourses_tsae'].value === 'Y'
      && (this.hasTsae(
        property.properties['typeformation'].value,
        property.properties['is_european'].value,
        property.properties['mscchoice'].value))
    ) {
      needed_type_doc_bourses.push('file_tsae');
    }
    // calcul liste des documents bourses absents
    for (const doc of needed_type_doc_bourses) {
      let my_doc_file = '';
      if (property.properties[doc] !== undefined && property.properties[doc].value !== '') {
        my_doc_file = JSON.parse(property.properties[doc].value);
        if (my_doc_file['name'].substring(my_doc_file['name'].length - 4).toLowerCase() !== '.pdf') {
          empty_doc_bourses.push(doc);
        }
      } else {
        empty_doc_bourses.push(doc);
      }
    }
    return empty_doc_bourses;
  }

  private checkProfXp(property) {
    for (const xp of property.properties['prof_xp'].value) {
      if (xp['from_tod'] !== undefined
        && xp['from_tod'] !== ''
        && xp['from_tof'] !== undefined
        && xp['from_tof'] !== ''
      ) {
        const from_tod_us = (xp['from_tod'].substring(6, 10) + xp['from_tod'].substring(3, 5) + xp['from_tod'].substring(0, 2));
        const from_tof_us = (xp['from_tof'].substring(6, 10) + xp['from_tof'].substring(3, 5) + xp['from_tof'].substring(0, 2));
        if (from_tof_us <= from_tod_us) {
          return false;
        }
      }
    }
    return true;
  }

  private checkAcadBackground(property) {
    for (const acad of property.properties['heb_line'].value) {
      if (acad['institution'] !== undefined && acad['institution'] === '') {
        return false;
      }
      if (acad['heb_from_tod'] !== undefined && acad['heb_from_tod'] === '') {
        return false;
      }
      if (acad['heb_from_tof'] !== undefined && acad['heb_from_tof'] === '') {
        return false;
      }
      if (acad['field_study'] === 'ot' && acad['field_other'] === '') {
        return false;
      }
    }
    return true;
  }

  private checkReco(property) {
    if (this.schemaService.authservice.user.login === property.properties['reco_email_1'].value
      || this.schemaService.authservice.user.login === property.properties['reco_email_2'].value
    ) {
      return false;
    }
    return true;
  }

  private checkDocs(property, checkProof: boolean = false) {
    let needed_type_doc: string[] = ['id', 'cv', 'cover', 'degree', 'transcript'];
    if (this.needLangDoc(
      property.properties['typeformation'].value,
      property.properties['fstchoice'].value,
      property.properties['sndchoice'].value,
      property.properties['trdchoice'].value,
      property.properties['nationality'].value,
      property.properties['dual_nationality'].value)
    ) {
      needed_type_doc.push('english');
    }

    if (property.properties['unemployed'] !== undefined && property.properties['unemployed'].value === 'O') {
      needed_type_doc.push('pole_emploi');
    }

    for (const doc of property.properties['docs'].value) {
      needed_type_doc = needed_type_doc.filter((elem) => {
        let doc_file = '';
        if (doc['doc_file'] !== undefined && doc['doc_file'].length > 0) {
          doc_file = JSON.parse(doc['doc_file']);
          return (elem !== doc['type_doc'])
            || (doc['doc_file'] === '')
            || (doc_file['name'].substring(doc_file['name'].length - 4).toLowerCase() !== '.pdf');
        }
        return true;
      });
    }

    for (const doc of property.properties['docs'].value) {
      let my_doc_file = '';
      if (doc['doc_file'] !== undefined && doc['doc_file'].length > 0) {
        my_doc_file = JSON.parse(doc['doc_file']);
        if (my_doc_file['name'].substring(my_doc_file['name'].length - 4).toLowerCase() !== '.pdf'
          && needed_type_doc.indexOf(doc['type_doc']) < 0
        ) {
          needed_type_doc.push(doc['type_doc']);
        }
      }
    }

    if (checkProof && !this.checkProofOfPayment(property)) {
      needed_type_doc.push('proof_of_payment');
    }

    needed_type_doc = needed_type_doc.map((elem) => {
      if (elem === 'english') {
        return 'language certificate';
      }
      return elem;
    });

    return needed_type_doc;
  }

  private setValidatedFlag(tabId: string, property: any) {
    const nom_prop = tabId + '_validated';
    const validatedProperty = property.properties[nom_prop];
    if (validatedProperty && validatedProperty.setValue) {
      validatedProperty.setValue('O');
    }
  }

  private checkNames(property: any) {
    // retourne vrai si les noms ne contiennent pas de caractères speciaux, sinon faux
    // adaptation de https://stackoverflow.com/a/26900132/10021442
    const nomFamille = property.properties['familyName'].value;
    const prenom = property.properties['givenName'].value;
    const maidenName = property.properties['maidenName'].value;
    const middleNames = property.properties['middleNames'].value;
    const reg = /[^A-Za-zÀ-ÖØ-öø-ÿ-'\ ]/g;
    // on regarde si le nom ne contient pas de caractères interdits
    return nomFamille.match(reg) === null
      && prenom.match(reg) === null
      && maidenName.match(reg) === null
      && middleNames.match(reg) === null;
  }

  // duplication nom et prénom saisis dans les champs nom et prénom valides (pour eventuelle maj dans le BO)
  private setValidNames(property: any) {
    // nom valide
    if (property.properties['familyName'] !== undefined && property.properties['familyName'].value !== '') {
      property.properties['familyNameValid'].setValue(property.properties['familyName'].value);
    } else {
      property.properties['familyNameValid'].setValue('');
    }
    // prénom valide
    if (property.properties['givenName'] !== undefined && property.properties['givenName'].value !== '') {
      property.properties['givenNameValid'].setValue(property.properties['givenName'].value);
    } else {
      property.properties['givenNameValid'].setValue('');
    }
  }

  private checkProofOfPayment(property) {
    let proof_file = '';
    if (property.properties['proof_of_payment'] !== undefined && property.properties['proof_of_payment'].value.length > 0) {
      proof_file = JSON.parse(property.properties['proof_of_payment'].value);
      return proof_file['name'].substring(proof_file['name'].length - 4).toLowerCase() === '.pdf';
    }
    return false;
  }

  private checkProofOfDownPayment(property) {
    let proof_file = '';
    if (property.properties['reg_proof_of_payment'] !== undefined && property.properties['reg_proof_of_payment'].value.length > 0) {
      proof_file = JSON.parse(property.properties['reg_proof_of_payment'].value[0].proofFile);
      return proof_file['name'].substring(proof_file['name'].length - 4).toLowerCase() === '.pdf';
    }
    return false;
  }

  private checkPhoto(property) {
    let proof_file = '';
    if (property.properties['uploadPhoto'] !== undefined && property.properties['uploadPhoto'].value.length > 0) {
      proof_file = JSON.parse(property.properties['uploadPhoto'].value);
      return proof_file['name'].substring(proof_file['name'].length - 4).toLowerCase() === '.jpg';
    }
    return false;
  }

  private checkFunding(property) {
    if ((property.properties['personal'] === undefined || property.properties['personal'].value === '')
      && (property.properties['fongecif'] === undefined || property.properties['fongecif'].value === '')
      && (property.properties['assedic'] === undefined || property.properties['assedic'].value === '')
      && (property.properties['frenchGov'] === undefined || property.properties['frenchGov'].value === '')
      && (property.properties['foreignGov'] === undefined || property.properties['foreignGov'].value === '')
      && (property.properties['mindef'] === undefined || property.properties['mindef'].value === '')
      && (property.properties['fasia'] === undefined || property.properties['fasia'].value === '')
      && (property.properties['otherFunding'] === undefined || property.properties['otherFunding'].value === '')
    ) {
      return false;
    } else {
      return true;
    }
  }

  private checkIdentity(property) {
    if ((property.properties['idcardno'] !== undefined && property.properties['idcardno'].value !== '')
      && (property.properties['idauthority'] !== undefined && property.properties['idauthority'].value !== '')
      && (property.properties['idissuedate'] !== undefined && property.properties['idissuedate'].value !== '')
      && (property.properties['idexpirydate'] !== undefined && property.properties['idexpirydate'].value !== '')
    ) {
      return true;
    }
    if ((property.properties['passportno'] !== undefined && property.properties['passportno'].value !== '')
      && (property.properties['passportauthority'] !== undefined && property.properties['passportauthority'].value !== '')
      && (property.properties['passportissuedate'] !== undefined && property.properties['passportissuedate'].value !== '')
      && (property.properties['passportexpirydate'] !== undefined && property.properties['passportexpirydate'].value !== '')
    ) {
      return true;
    }
    return false;
  }

  ngOnInit(): void {
    const config = environment;
    this.bos.wsurl = config.bowsurl;
    this.schemaService.wsurl = config.wsurl;
    this.schemaService.formid = config.formid;
    this.schemaService.webappurl = config.webappurl;
    this.schemaService.fileAutoUpload = true;
    this.schemaService.baseurl = config.baseurl;
    this.paymenturl = config.paymenturl;
    this.bos.getPays().subscribe((response: any) => {
      if (response) {
        this.countries = response['pays'];
      }
    });
    this.bos.getJurys().subscribe((responsej: any) => {
      if (responsej) {
        this.jurys = responsej['jury'];
      }
    });
    this.bos.getFormations().subscribe((responsef: any) => {
      if (responsef) {
        this.formations = responsef;
        this.formations.push({description: '', enum: ['']});
      }
    });
    this.bos.getFormationsType('PG_DIP').subscribe((responsep: any) => {
      this.formationsPgdip = responsep;
      this.formationsPgdip.push({description: '', enum: ['']});
    });
    // Mise à jour de la liste des établissements du bac
    this.scService.options.setInstitutionSchema = (codePays, formProperty) => {
      this.bos.getInternationalInstitutions(codePays).subscribe((liste) => {
        formProperty.schema.oneOf = liste;
        formProperty.setSchema(formProperty.schema);
      });
    };

     // Calcul de la liste des villes de naissance
     this.scService.options.setVilleNaissanceSchema = (deptBirth, formProperty) => {
      this.bos.getCommuneDept(deptBirth).subscribe((liste) => {
        formProperty.schema.oneOf = liste;
        formProperty.setSchema(formProperty.schema);
      });
    }

    // Calcul de la liste des villes adresse rosalie
    this.scService.options.setVilleAdresseSchema = (zip, formProperty) => {
      this.bos.getCommuneZip(zip).subscribe((liste) => {
        formProperty.schema.oneOf = liste;
        formProperty.setSchema(formProperty.schema);
      });
    }


    const tabAction = (property: any, options: any) => {
      this.schemaService.formservice.clearMessages();
      this.desactivateAllfields(property.schema, ['home']);
      const tabId = options.tabId;
      const tabIndex = options.tabIndex;
      const value: {} = SchemaUtils.getValuesOfTab(tabId, property);
      if (property.properties['fatherPays'] !== undefined && property.properties['fatherPays'] === 'FR') {
        property.properties['paysPereEtranger'] = 'N';
      } else {
        property.properties['paysPereEtranger'] = 'O';
      }

      if (SchemaUtils.isTabValid(tabId, property)) {
        if (property.properties['typeformation'].value === 'ING') {
          property.properties['show_payment'].setValue('N');
        } else {
          property.properties['show_payment'].setValue('O');
        }
        let allOk = true;
        if (tabId === 'background') {
          if (!this.checkProfXp(property)) {
            this.schemaService.formservice.addMessage('danger', 'Professional experience date error : the begin date must be before the end date');
            allOk = false;
          }
          if (!this.checkAcadBackground(property)) {
            this.schemaService.formservice.addMessage('danger', 'Academic background : institution information, date from/to or \'other field\' of study is missing');
            allOk = false;
          }
          if (!this.checkReco(property)) {
            this.schemaService.formservice.addMessage('danger', 'Invalid email for recommendor 1 or recommendor 2');
            allOk = false;
          }
        }

        if (tabId === 'docs') {
          if (!this.cleanupDocsProperty(property)) {
            allOk = false;
          }

          const missing_docs = this.checkDocs(property);
          if (missing_docs.length > 0) {
            for (const doc of missing_docs) {
              if (doc === 'cover') {
                this.schemaService.formservice.addMessage('danger', 'Cover letter is missing or is not a pdf file');
              } else {
                this.schemaService.formservice.addMessage('danger', doc + ' document is missing or is not a pdf file');
              }
              allOk = false;
            }
          }
          if (property.properties['typeformation'].value === 'ING') {
            // si le type de formation est ingénieur, on saute l'onglet payment
            property.schema.tabs[tabIndex].nextTab = 'validation';
          }
        }

        if (tabId === 'choice') {
          if (property.properties['typeformation'].value === 'PG_DIP') {
            property.schema.properties['step1'].value = '<div class=\'alert alert-info\'>Step 1 : Your choice</br></br>Step 2 : Personal information</br></br>Step 3 : Educational and professional background</br></br>Step 4 : Language, mother tongue and foreign languages</br></br>Step 5 : Funding information</br></br>Step 6 : Upload your documents</br></br>Step 7 : Payment (except for Ingénieur ISAE-SUPAERO)</br></br>Step 8 : Program application validation</div>';
          } else {
            property.schema.properties['step1'].value = '<div class=\'alert alert-info\'>Step 1 : Your choice</br></br>Step 2 : Personal information</br></br>Step 3 : Educational and professional background</br></br>Step 4 : Language, mother tongue and foreign languages</br></br>Step 5 : Funding information</br></br>Step 6 : Upload your documents</br></br>Step 7 : Payment (except for Ingénieur ISAE-SUPAERO)</br></br>Step 8 : Program application validation</br></br>Step 9 : Scholarship application validation</div>';
          }
        }
        if (tabId === 'personal') {
          this.setValidNames(property);
          value['familyNameValid'] = property.properties['familyNameValid'].value;
          value['givenNameValid'] = property.properties['givenNameValid'].value;
          let first_nat_is_europ = property.properties['nationality'].value ? this.countries.filter( elt => elt.code === property.properties['nationality'].value)[0].pays_ue === "O" : false;
          let dual_nat_is_europ = (property.properties['dual_nationality'].value && property.properties['dual_nationality'].value != "--") ? this.countries.filter( elt => elt.code === property.properties['dual_nationality'].value)[0].pays_ue === "O" : false;
          value['is_european'] = (first_nat_is_europ || dual_nat_is_europ) ? 'O' : 'N';
          if(property.properties['is_european']){
            property.properties['is_european'].setValue(value['is_european']);
          }
          const schols: string[] = this.showOrHideScholarship(
            property.properties['typeformation'].value,
            property.properties['nationality'].value,
            property.properties['dual_nationality'].value,
            property.properties['fstchoice'].value,
            property.properties['sndchoice'].value,
            property.properties['trdchoice'].value,
            property.properties['mscchoice'].value,
          property.properties['is_european'].value,
          property.properties['programme']?.value);
          schols.forEach((schol) => {
            property.properties[schol].setValue('Y');
          });
          if (!this.checkNames(property)) {
            this.schemaService.formservice.addMessage('danger', 'Special characters are not allowed in Family, Given, Maiden or Middle names');
            allOk = false;
          }
        }

        if (tabId === 'payment' && !this.checkProofOfPayment(property)) {
          this.schemaService.formservice.addMessage('danger', 'Proof of payment is missing or is not a pdf file');
          allOk = false;
        }

        if (tabId === 'documents2' && !this.checkProofOfDownPayment(property)) {
          this.schemaService.formservice.addMessage('danger', 'Proof of down payment is missing or is not a pdf file');
          allOk = false;
        }
        if (tabId === 'registration' && !this.checkPhoto(property)) {
          this.schemaService.formservice.addMessage('danger', 'Photo is missing or is not a jpg file');
          allOk = false;
        }
        if (tabId === 'registration' && !this.checkIdentity(property)) {
          this.schemaService.formservice.addMessage('danger', 'identity information (identity card or passport) is missing.');
          allOk = false;
        }
        if (tabId === 'funding2' && !this.checkFunding(property)) {
          this.schemaService.formservice.addMessage('danger', 'Funding details information is missing');
          allOk = false;
        }

        if (allOk) {
          if (tabId !== 'home') {
            value[tabId + '_validated'] = 'O';
            this.setValidatedFlag(tabId, property);
          }
          this.schemaService.formservice.answerForm(JSON.stringify(value)).subscribe((response: any) => {
            const resp = response;
            if (resp.result !== undefined && resp.result === 'OK') {
              this.schemaService.formservice.addMessage('success', tabId + ' data update done...');
              const currentTab = property.schema.tabs[tabIndex];
              if (currentTab.nextTab !== undefined && currentTab.id !== 'validation') {
                // TODO chercher l'onglet suivant visible et non pas seulement le prochain
                // désactivation de l'onglet courant
                currentTab.active = false;
                // activation de l'onglet suivant
                SchemaUtils.getTabFromTabId(currentTab.nextTab, property).active = true;
                window.scrollTo(0, 0);
              }
            } else {
              this.sessionExpired();
            }
          });
        }
      } else {
        this.handleValidationError(property, tabId);
      }
    };
    const validationAction = (property: any, options: any) => {
      this.schemaService.formservice.clearMessages();
      const tabId = options.tabId;
      const tabIndex = options.tabIndex;
      const value: {} = SchemaUtils.getValuesOfTab(tabId, property);
      this.desactivateAllfields(property.schema, ['home']);
      if (SchemaUtils.isAllTabsValid(property)) {
        const checkProof: boolean = property.properties['typeformation'].value !== 'ING';
        const missing_docs = this.checkDocs(property, checkProof);
        if (missing_docs.length > 0) {
          for (const doc of missing_docs) {
            if (doc === 'cover') {
              this.schemaService.formservice.addMessage('danger', 'Cover letter is missing or is not a pdf file');
            } else {
              this.schemaService.formservice.addMessage('danger', doc + ' document is missing or is not a pdf file');
            }
          }
        } else {
          if (this.checkTabValidation(property)) {
            this.setValidNames(property);
            value['familyNameValid'] = property.properties['familyNameValid'].value;
            value['givenNameValid'] = property.properties['givenNameValid'].value;
            this.schemaService.formservice.answerForm(JSON.stringify(value)).subscribe((resp: any) => {
              if (resp.result !== undefined && resp.result === 'OK') {
                this.bos.validateApplication().subscribe((response) => {
                  if (property.properties['admis'] !== undefined && property.properties['candidat_validation'] === 'O') {
                    if (property.properties['admis'] === 'O') {
                      property.properties['show_admis'] = 'O';
                    }
                    if (property.properties['admis'] === 'N') {
                      property.properties['show_admis'] = 'N';
                    }
                    if (property.properties['admis'] === 'L') {
                      property.properties['show_admis'] = 'L';
                    }
                    if (property.properties['admis'] === 'C') {
                      property.properties['show_admis'] = 'C';
                    }
                    if (property.properties['admis'] === 'R') {
                      property.properties['show_admis'] = 'R';
                    }
                    if (property.properties['admis'] === '-') {
                      property.properties['show_admis'] = 'NA';
                    }
                  } else {
                    property.properties['show_admis'] = 'NA';
                  }
                  if (response.result !== undefined && response.result === 'OK') {
                    property.properties['show_bourses'].setValue('O');
                    property.properties['show_buttons'].setValue('N');
                    property.properties['show_buttons_reg'].setValue('N');
                    property.properties['show_buttons_bourses'].setValue('N');
                    this.desactivateAllfields(property.schema, ['choice', 'personal', 'background', 'langs', 'fund', 'docs', 'payment', 'validation']);
                    const schols: string[] = this.showOrHideScholarship(
                      property.properties['typeformation'].value,
                      property.properties['nationality'].value,
                      property.properties['dual_nationality'].value,
                      property.properties['fstchoice'].value,
                      property.properties['sndchoice'].value,
                      property.properties['trdchoice'].value,
                      property.properties['mscchoice'].value,
                    property.properties['is_european'].value,
                    property.properties['programme']?.value);
                    schols.forEach((schol) => {
                      property.properties[schol].setValue('Y');
                    });
                    if (schols.length === 0) {
                      property.properties['show_buttons_bourses'].setValue('N');
                      property.properties['no_bourse'].setValue('Y');
                      this.schemaService.formservice.addMessage('info', 'You are not eligible to apply for any scholarship program');
                    } else {
                      property.properties['no_bourse'].setValue('N');
                      property.properties['show_buttons_bourses'].setValue('O');
                    }
                    // fermeture accès aux candidatures bourses
                    if (property.properties['typeformation'].value === 'ING') {
                      property.properties['no_bourse'].setValue('Y');
                      property.properties['show_bourses'].setValue('N');
                    }
                    // on ferme l'acces au bourses à partir du 01/12
                    // COMMENTER LA LIGNE SUIVANTE POUR OUVRIR
                    //property.properties['show_bourses'].setValue('N');
                    this.schemaService.formservice.addMessage('success', 'Validation of application done');
                  } else {
                    this.schemaService.formservice.addMessage('danger', response.message || 'Error');
                  }
                  if (property.schema.tabs[tabIndex].nextTab !== undefined) {
                    // désactivation de l'onglet courant
                    property.schema.tabs[tabIndex].active = false;
                    // activation de l'onglet suivant
                    SchemaUtils.getTabFromTabId(property.schema.tabs[tabIndex].nextTab, property).active = true;
                  }
                });
              } else {
                this.sessionExpired();
              }
            });
          } else {
            this.handleTabValidationError(property);
          }
        }
      } else {
        this.handleValidationErrorAllTabs(property);
      }
    };
    const showPdfAction = (property: any, options: any) => {
      const data = this.prepareDataForCandFileReport(property.root.value, property.root.schema);
      this.getApplicationFile(data);
    };
    const showScholPdfAction = (property: any, options: any) => {
      const data = this.prepareDataForCandFileReport(property.root.value, property.root.schema);
      this.getScholApplicationFile(data, property.root.schema);
    };
    const showPdfRegAction = (property: any, options: any) => {
      const data = this.prepareDataForCandFileReport(property.root.value, property.root.schema);
      this.getRegApplicationFile(data);
    };
    const validationBourse = (property: any, options: any) => {
      this.schemaService.formservice.clearMessages();
      const tabId = options.tabId;
      const tabIndex = options.tabIndex;
      const value: {} = SchemaUtils.getValuesOfTab(tabId, property);
      let allOk = true;
      this.desactivateAllfields(property.schema, ['home']);
      if (tabId === 'bourses') {
        const missing_docs_bourses = this.checkDocsBourses(property);
        const list_of_bourses = this.checkListBourses(property);
        if (missing_docs_bourses.length > 0) {
          for (const doc of missing_docs_bourses) {
            if (doc !== 'file_tuition') {
              this.schemaService.formservice.addMessage('danger', doc + ' document is missing or is not a pdf file');
            } else {
              this.schemaService.formservice.addMessage('danger', 'Motivation letter is missing or is not a pdf file');
            }
            allOk = false;
          }
        }
      }
      if (allOk) {
        if (SchemaUtils.isTabValid(tabId, property)) {
          this.schemaService.formservice.answerForm(JSON.stringify(value)).subscribe((respAnswer: any) => {
            if (respAnswer.result !== undefined && respAnswer.result === 'OK') {
              this.bos.validateBourse().subscribe((resp) => {
                if (resp.result !== undefined && resp.result === 'OK') {
                  property.properties['show_bourses'].setValue('O');
                  property.properties['show_buttons'].setValue('N');
                  property.properties['show_buttons_bourses'].setValue('N');
                  property.properties['show_buttons_reg'].setValue('N');
                  this.desactivateAllfields(property.schema, ['bourses']);
                  this.schemaService.formservice.addMessage('success', 'Your application to scholarship programs is complete. Thanks!');
                } else {
                  this.schemaService.formservice.addMessage('danger', resp.message || 'Error');
                }
              });
              if (property.schema.tabs[tabIndex].nextTab !== undefined) {
                // désactivation de l'onglet courant
                property.schema.tabs[tabIndex].active = false;
                // activation de l'onglet suivant
                SchemaUtils.getTabFromTabId(property.schema.tabs[tabIndex].nextTab, property).active = true;
              }
            } else {
              this.sessionExpired();
            }
          });

        } else {
          this.handleValidationError(property, tabId);
        }
      }
    };

    const validationTuition = (property: any, options: any): void => {
      this.schemaService.formservice.clearMessages();
      const tabId = options.tabId;
      const value: {} = SchemaUtils.getValuesOfTab(tabId, property);
      this.desactivateAllfields(property.schema, ['home']);

      const onFulfilled = () => {
        this.schemaService.formservice.addMessage('success', 'Validation of registration done');
        this.schemaService.formservice.addMessage('success', 'Your application to education programs is complete. Thanks!');
      };
      const onRejected = (reason: any) => this.schemaService.formservice.addMessage('danger', reason.message || 'Error');

      this.schemaService.formservice
        .answerForm(JSON.stringify(value))
        .subscribe((response: any) => {
          if (response.result && response.result === 'OK') {
            this.bos.validateTuition().subscribe(onFulfilled, onRejected);
          } else {
            this.handleTabValidationError(property);
          }
        });
    };

    const validationRegistrationDocs = (property: any, options: any): void => {
      this.schemaService.formservice.clearMessages();
      const tabId = options.tabId;
      const tabIndex = options.tabIndex;
      const value: {} = SchemaUtils.getValuesOfTab(tabId, property);
      this.desactivateAllfields(property.schema, ['home']);

      if (!SchemaUtils.isTabValid(tabId, property)) {
        this.handleValidationError(property, tabId);
        return;
      }

      const onRejected = (reason: any) => this.schemaService.formservice.addMessage('danger', reason.message || 'Error');

      this.schemaService.formservice
        .answerForm(JSON.stringify(value))
        .subscribe((response: any) => {
          if (response.result && response.result === 'OK') {
            this.scService.validateStep('validation_registration_docs').then(_ => {
              if (property.schema.tabs[tabIndex].nextTab !== undefined) {
                // désactivation de l'onglet courant
                property.schema.tabs[tabIndex].active = false;
                // activation de l'onglet suivant
                SchemaUtils.getTabFromTabId(property.schema.tabs[tabIndex].nextTab, property).enabled = true;
                SchemaUtils.getTabFromTabId(property.schema.tabs[tabIndex].nextTab, property).active = true;
              }
            }).catch(onRejected);
          } else {
            this.handleTabValidationError(property);
          }
        });
    };

    const validationInscription = (property: any, options: any) => {
      this.schemaService.formservice.clearMessages();
      const tabId = options.tabId;
      const value: {} = SchemaUtils.getValuesOfTab(tabId, property);
      let allOk = true;
      this.desactivateAllfields(property.schema, ['home']);
      if (!this.checkProofOfDownPayment(property)) {
        this.schemaService.formservice.addMessage('danger', 'Proof of down payment is missing or is not a pdf file');
        allOk = false;
      }
      if (!this.checkPhoto(property)) {
        this.schemaService.formservice.addMessage('danger', 'Photo is missing or is not a jpg file');
        allOk = false;
      }
      if (!property.properties['acknowledgementReg'].value) {
        this.schemaService.formservice.addMessage('danger', 'You must tick : I testify on my honor to the accuracy...');
        allOk = false;
      }

      if (allOk) {
        if (this.checkTabRegister(property)) {
          property.properties['show_buttons_reg'].setValue('N');
          this.schemaService.formservice.answerForm(JSON.stringify(value)).subscribe((respAnswer: any) => {
            if (respAnswer.result !== undefined && respAnswer.result === 'OK') {
              this.bos.validateRegistration().subscribe((response) => {
                if (response.result !== undefined && response.result === 'OK') {
                  property.properties['show_buttons'].setValue('N');
                  property.properties['show_buttons_bourses'].setValue('N');
                  property.properties['show_buttons_reg'].setValue('N');
                  this.desactivateAllfields(property.schema, ['registration', 'funding2', 'parents', 'siblings', 'address', 'documents2', 'register']);
                  this.schemaService.formservice.addMessage('success', 'Validation of registration done');
                  this.schemaService.formservice.addMessage('success', 'Your application to education programs is complete. Thanks!');
                } else {
                  this.schemaService.formservice.addMessage('danger', response.message || 'Error');
                }
              });
            }
          });
        } else {
          this.handleTabValidationError(property);
        }
      }
    };

    const payonline = (property, options) => {
      const pays_pb = this.countries.filter((pays) => {
        return ((pays['code'] === property.root.properties['country'].value));
      });
      let ville_pb = property.root.properties['city'].value;
      if (property.root.properties['country'].value === 'FR') {
        ville_pb = property.root.properties['cityFr'].value;
      }

      const url = this.paymenturl + '?entite=DFM_candidature&montant=100&courriel=' + this.auth.user.login
        + '&cursus=' + property.root.properties['typeformation'].value
        + '&prenom=' + property.root.properties['givenName'].value
        + '&nom=' + property.root.properties['familyName'].value
        + '&locale=en_GB'
        + '&nature=fraisdoss'
        + '&cp=' + property.root.properties['zip'].value
        + '&adresse=' + property.root.properties['address'].value
        + '&ville=' + ville_pb
        + '&code_pays=' + pays_pb[0].codeiso;
      window.open(url, 'blank');
    };

    const tuition_payonline = (property, options) => {
      const pays_pb = this.countries.filter((pays) => {
        return ((pays['code'] === property.root.properties['country'].value));
      });
      let ville_pb = property.root.properties['city'].value;
      if (property.root.properties['country'].value === 'FR') {
        ville_pb = property.root.properties['cityFr'].value;
      }
      const url = this.paymenturl + '?entite=DFM&courriel=' + this.auth.user.login
        + '&montant=' + this.montant
        + '&prenom=' + property.root.properties['givenName'].value
        + '&nom=' + property.root.properties['familyName'].value
        //  + "&cursus=" + property.root.properties["programme"].value  A CORRIGER NE FCT PAS
        + '&locale=en_GB'
        + '&nature=tuition'
        + '&paiement3x=0'
        + '&cp=' + property.root.properties['zip'].value
        + '&adresse=' + property.root.properties['address'].value
        + '&ville=' + ville_pb
        + '&code_pays=' + pays_pb[0].codeiso;
      window.open(url, 'blank');
    };

    const tuition_payonline3 = (property, options) => {
      const pays_pb = this.countries.filter((pays) => {
        return ((pays['code'] === property.root.properties['country'].value));
      });
      let ville_pb = property.root.properties['city'].value;
      if (property.root.properties['country'].value === 'FR') {
        ville_pb = property.root.properties['cityFr'].value;
      }
      const url = this.paymenturl + '?entite=DFM&courriel=' + this.auth.user.login
        + '&montant=' + this.montant
        + '&prenom=' + property.root.properties['givenName'].value
        + '&nom=' + property.root.properties['familyName'].value
        //  + "&cursus=" + property.root.properties["programme"].value A corriger
        + '&locale=en_GB'
        + '&nature=tuition'
        + '&paiement3x=1'
        + '&cp=' + property.root.properties['zip'].value
        + '&adresse=' + property.root.properties['address'].value
        + '&ville=' + ville_pb
        + '&code_pays=' + pays_pb[0].codeiso;

      window.open(url, 'blank');
    };

    const payonlinereg = (property, options) => {
      let url = '';
      let programme = '';
      if (property.root.properties['programmePb'] === undefined) {
        programme = 'DFM';
      } else {
        programme = property.root.properties['programmePb'].value;
      }
      const pays_pb = this.countries.filter((pays) => {
        return ((pays['code'] === property.root.properties['country'].value));
      });
      let ville_pb = property.root.properties['city'].value;
      if (property.root.properties['country'].value === 'FR') {
        ville_pb = property.root.properties['cityFr'].value;
      }

      if (property.root.properties['typeformation'].value === 'MSC') {
        url = this.paymenturl + '?entite=DFM&montant=1200&courriel=' + this.auth.user.login
          + '&nom=' + property.root.properties['familyName'].value
          + '&prenom=' + property.root.properties['givenName'].value
          + '&cursus=' + programme
          + '&locale=en_GB'
          + '&nature=acompte'
          + '&cp=' + property.root.properties['zip'].value
          + '&adresse=' + property.root.properties['address'].value
          + '&ville=' + ville_pb
          + '&code_pays=' + pays_pb[0].codeiso;
      } else {
        url = this.paymenturl + '?entite=DFI&montant=1500&courriel=' + this.auth.user.login
          + '&nom=' + property.root.properties['familyName'].value
          + '&prenom=' + property.root.properties['givenName'].value
          + '&cursus=' + programme
          + '&locale=en_GB'
          + '&nature=acompte'
          + '&cp=' + property.root.properties['zip'].value
          + '&adresse=' + property.root.properties['address'].value
          + '&ville=' + ville_pb
          + '&code_pays=' + pays_pb[0].codeiso;
      }
      window.open(url, 'blank');
    };

    this.schemaService.actions['vhome'] = tabAction;
    this.schemaService.actions['vhomer'] = tabAction;
    this.schemaService.actions['vchoice'] = tabAction;
    this.schemaService.actions['vpersonal'] = tabAction;
    this.schemaService.actions['vbackground'] = tabAction;
    this.schemaService.actions['vlangs'] = tabAction;
    this.schemaService.actions['vfund'] = tabAction;
    this.schemaService.actions['vdocs'] = tabAction;
    this.schemaService.actions['vpayment'] = tabAction;
    this.schemaService.actions['vsave'] = tabAction;
    this.schemaService.actions['vvalidation'] = validationAction;
    this.schemaService.actions['showpdf'] = showPdfAction;
    this.schemaService.actions['showscholpdf'] = showScholPdfAction;
    this.schemaService.actions['showpdfreg'] = showPdfRegAction;
    this.schemaService.actions['vbourse'] = validationBourse;
    this.schemaService.actions['payonline'] = payonline;
    this.schemaService.actions['tuition_payonline'] = tuition_payonline;
    this.schemaService.actions['tuition_payonline3'] = tuition_payonline3;
    this.schemaService.actions['payonlinereg'] = payonlinereg;
    this.schemaService.actions['vresult'] = tabAction;
    this.schemaService.actions['vregistration_docs'] = validationRegistrationDocs;
    this.schemaService.actions['vtuition'] = validationTuition;
    this.schemaService.actions['vregistration'] = tabAction;
    this.schemaService.actions['vfunding2'] = tabAction;
    this.schemaService.actions['vparents'] = tabAction;
    this.schemaService.actions['vsiblings'] = tabAction;
    this.schemaService.actions['vsiblings'] = tabAction;
    this.schemaService.actions['vaddress'] = tabAction;
    this.schemaService.actions['vdocuments2'] = tabAction;
    this.schemaService.actions['vregister'] = validationInscription;

    const fieldValidators = {};

    fieldValidators['/typeformation'] = (value, property, form) => {
      if (value === 'PG_DIP') {
        form.schema.properties.fstchoice.oneOf = this.formationsPgdip;
        form.schema.properties.sndchoice.oneOf = this.formationsPgdip;
        form.schema.properties.trdchoice.oneOf = this.formationsPgdip;
      }
      if (value === 'AM') {
        form.schema.properties.fstchoice.oneOf = this.formations;
        form.schema.properties.sndchoice.oneOf = this.formations;
        form.schema.properties.trdchoice.oneOf = this.formations;
      }
    };

    fieldValidators['/sndchoice'] = (value, property, form) => {
      let errors = null;
      const firstchoice = form.getProperty('fstchoice');
      const trdchoice = form.getProperty('trdchoice');
      if (value !== undefined && value !== '' && (firstchoice.value === value || trdchoice.value === value)) {
        errors = [{sndchoice: {expectedValue: '!== fstchoice && !== trdchoice', actualValue: value}}];
      }
      return errors;
    };

    fieldValidators['/fstchoice'] = (value, property, form) => {
      let errors = null;
      const secondchoice = form.getProperty('sndchoice');
      const trdchoice = form.getProperty('trdchoice');
      if (secondchoice.value === value || trdchoice.value === value) {
        errors = [{fstchoice: {expectedValue: '!== sndchoice && !== trdchoice', actualValue: value}}];
      }
      return errors;
    };

    fieldValidators['/trdchoice'] = (value, property, form) => {
      let errors = null;
      const secondchoice = form.getProperty('sndchoice');
      const firstchoice = form.getProperty('fstchoice');
      if (value !== undefined && value !== '' && (secondchoice.value === value || firstchoice.value === value)) {
        errors = [{fstchoice: {expectedValue: '!== sndchoice && !== firstchoice', actualValue: value}}];
      }
      return errors;
    };

    fieldValidators['/dateofbirth'] = (value, property, form) => {
      let errors = null;
      if (value === undefined || value === null || value === '') {
        errors = [{dateofbirth: {expectedValue: 'a date', actualValue: value}}];
      }
      return errors;
    };

    fieldValidators['/docs'] = (value, property, form) => {
      let errors = null;
      let needed_type_doc: string[] = ['id', 'cv', 'cover', 'degree', 'transcript', 'english'];
      for (const doc of value) {
        needed_type_doc = needed_type_doc.filter((elem) => {
          return elem !== doc['type_doc'];
        });
      }
      if (needed_type_doc.length > 0) {
        errors = [{docs: {'Missing documents': needed_type_doc}}];
      }
      return errors;
    };

    fieldValidators['/heb_line'] = (value, property, form) => {
      const errors = null;
      return errors;
    };

    fieldValidators['/acknowledgement'] = (value, property, form) => {
      let errors = null;
      if (!!!value && property.root.properties['typeformation'].value !== 'ING') {
        errors = [{acknowledgement: {expectedValue: 'true', actualValue: value}}];
      }
      return errors;
    };

    fieldValidators['/val_testify2'] = (value, property, form) => {
      let errors = null;
      if (!!!value) {
        errors = [{val_testify2: {expectedValue: 'true', actualValue: value}}];
      }
      return errors;
    };

    this.schemaService.fieldValidators = fieldValidators;

    this.schemaService.onLoadCallback = (model, schema) => {
      if (model['typeAuditeur'] === undefined) {
        model['typeAuditeur'] = '';
      }
      if (model['typeformation'] === 'PG_DIP') {
        schema.properties['fstchoice']['oneOf'] = this.formationsPgdip;
        schema.properties['sndchoice']['oneOf'] = this.formationsPgdip;
        schema.properties['trdchoice']['oneOf'] = this.formationsPgdip;
      }
      if (model['typeformation'] === 'AM') {
        schema.properties['fstchoice']['oneOf'] = this.formations;
        schema.properties['sndchoice']['oneOf'] = this.formations;
        schema.properties['trdchoice']['oneOf'] = this.formations;
      }
      if (model['typeformation'] === 'MSC') {
        schema.properties['bourses_tuition']['title'] = 'Tuition Waiver';
      }

      const tuitionIndex = schema.tabs.findIndex(t => t.id === 'tuition');
      schema.tabs[tuitionIndex].enabled = model['registration_docs_validated'] === 'O';

      this.desactivateAllfields(schema, ['home']);
      model['accesPortail'] = 'cand';
      model['campagne'] = '2025';
      if (model['typeformation'] === 'ING') {
        model['show_payment'] = 'N';
      } else {
        model['show_payment'] = 'O';
      }
      if (model['fatherPays'] !== undefined && model['fatherPays'] === 'FR') {
        model['paysPereEtranger'] = 'N';
      } else {
        model['paysPereEtranger'] = 'O';
      }
      /* Modifier le pavé des steps dans le cas de PG_DIP : pas de step 9 scholarship */
      if (model['typeformation'] !== undefined && model['typeformation'] === 'PG_DIP') {
        schema.properties.step1.value = '<div class=\'alert alert-info\'>Step 1 : Your choice</br></br>Step 2 : Personal information</br></br>Step 3 : Educational and professional background</br></br>Step 4 : Language, mother tongue and foreign languages</br></br>Step 5 : Funding information</br></br>Step 6 : Upload your documents</br></br>Step 7 : Payment (except for Ingénieur ISAE-SUPAERO)</br></br>Step 8 : Program application validation</div>';
      }

      /* Modifie le titre du fieldset dans le cas du paiement de l'acompte des frais de scolarité pour les ingénieurs (500€) */
      if (model['typeformation'] !== undefined && model['typeformation'] === 'MSC') {
        schema.fieldsets[25].title = 'Down payment of tuition fees : 1200€ (not refunded in case of withdraw)';
      }else {
        schema.fieldsets[25].title = 'Down payment of tuition fees : 1500€ (not refunded in case of withdraw)';
      }



      model['show_result'] = 'N';
      if (model['juryisae'] !== undefined && model['juryisae'] !== '') {
        const myJury = this.jurys.filter(ele => ele.id_jury === model['juryisae']);
        if (myJury.length > 0) {
          model['show_result'] = myJury[0].resultats_publies;
          model['show_result_bourse'] = myJury[0].resultats_brs_publies;
        } else {
          model['show_result'] = 'O';
        }
      }

      model['result_data'] = 'No result yet.';
      model['programmeLibelle'] = model['programme'];

      if (model['admis'] !== undefined && model['candidat_validation'] === 'O' && model['show_result'] === 'O') {
        schema.tabs[0].nextTab = 'registration';
        model['programmePb'] = model['programme'];
        if (model['admis'] === 'O') {
          model['show_admis'] = 'O';
          if (model['programme'] === 'MAE') {
            model['result_data'] = 'Congratulations, you are admitted in Master of Science MAE.';
            model['programmeLibelle'] = 'Master of Science AE.';
          } else {
            if (model['programme'] === 'TUM') {
              model['result_data'] = 'Congratulations, you are admitted in Master of Science TUM.';
              model['programmeLibelle'] = 'Master of Science TUM.';
            } else {
              if (model['typeformation'] === 'ING') {
                model['result_data'] = 'Congratulations, you are admitted in Cycle Ingénieur ISAE-SUPAERO.';
                model['programmeLibelle'] = 'Ingénieur ISAE-SUPAERO.';
              } else {
                const myFormation = this.formations.filter(ele => ele.enum[0] === model['programme']);
                model['result_data'] = 'Congratulations, you are admitted in ' + myFormation[0].description;
                model['programmeLibelle'] = myFormation[0].description;
              }
            }
          }
        }
        if (model['admis'] === 'N') {
          model['show_admis'] = 'N';
          model['result_data'] = 'You are not admitted.';
        }
        if (model['admis'] === 'L') {
          model['show_admis'] = 'L';
          model['result_data'] = 'You are in waiting list.';
        }
        if (model['admis'] === 'R') {
          model['show_admis'] = 'R';
          model['result_data'] = 'Your application is postponed. Postponed status means your application file remains in process for the other admission sessions.';
        }

        if (model['admis'] === 'C') {
          if (model['programme'] === 'MAE') {
            model['result_data'] = 'Congratulations, you are admitted in Master of Science MAE under condition to provide english language certificate.';
            model['programmeLibelle'] = 'Master of Science MAE.';
          } else {
            if (model['programme'] === 'TUM') {
              model['result_data'] = 'Congratulations, you are admitted in Master of Science TUM under condition to provide english language certificate.';
              model['programmeLibelle'] = 'Master of Science TUM.';
            } else {
              const myFormation = this.formations.filter(ele => ele.enum[0] === model['programme']);
              model['result_data'] = 'Congratulations, you are admitted in ' + myFormation[0].description + ' under condition to provide english language certificate.';
            }
          }
          model['show_admis'] = 'C';
        }
        if (model['admis'] === '-') {
          model['show_admis'] = 'NA';
          model['result_data'] = 'No result yet.';
        }
        // paiement du solde des frais de formation/scolarité
        if (model['fraisformation'] !== undefined) {

          if (model['mscchoice'] !== undefined && model['mscchoice'] === 'MAE') {
            this.montant = parseInt(model['fraisformation'], 10) / 2;
          } else {
            this.montant = parseInt(model['fraisformation'], 10);
          }
          if (this.montant > 0) {
            if (model['acompte'] !== undefined
              && (model['acompte'].indexOf('1000') !== -1
                || model['acompte'].indexOf('985') !== -1
                || model['acompte'].indexOf('960') !== -1)
              && model['reg_proof_of_payment'] !== undefined
              && model['reg_proof_of_payment'] !== ''
            ) {
              if (model['acompte'].indexOf('985') !== -1) {
                this.montant = this.montant - 985;
              } else {
                if (model['acompte'].indexOf('960') !== -1) {
                  this.montant = this.montant - 960;
                } else {
                  this.montant = this.montant - 1000;
                }
              }
            }
            schema.properties['tuition_total_help'].value = schema.properties['tuition_total_help'].value + this.montant.toString() + '€';
            if (this.montant <= 12000) {
              model['show_tuition_payment'] = 'O';
              model['show_tuition_payment_one'] = 'O';
            } else {
              model['show_tuition_payment'] = 'O';
              model['show_tuition_payment_one'] = 'N';
            }

            if (schema.properties['montant_ins']) {
              schema.properties['montant_ins'].value = this.montant;
            }
          } else {
            schema.properties['tuition_total_help'].value = schema.properties['tuition_total_help'].value + ' You don\'t have to pay any tuition fees.';
            model['show_tuition_payment'] = 'N';
            model['show_tuition_payment_one'] = 'N';
          }
        } else {
          schema.properties['tuition_total_help'].value = schema.properties['tuition_total_help'].value + '--€';
          schema.properties['tuition_payment_help'].value = schema.properties['tuition_payment_help'].value + '--€';
        }
        // fin solde
      } else {
        model['show_admis'] = 'NA';
        model['result_data'] = 'No result yet.';
      }

      model['result_bourse_data'] = 'No result yet.';
      if (model['typeformation'] === 'AM') {
        model['result_bourse_data'] = 'You are not eligible to apply for any scholarship program.';
      } else {
        if (!this.hasScholarshipApplication(
          model['typeformation'],
          model['bourses_mbda'],
          model['bourses_gifas'],
          model['bourses_cedar'],
          model['bourses_isae'],
          model['bourses_arise'],
          model['bourses_tuition'],
          model['bourses_tsae'])
        ) {
          model['result_bourse_data'] = 'No application.';
        } else {
          if (model['decisionbourse'] !== undefined && model['decisionbourse'] !== '' && model['show_result_bourse'] === 'O') {
            model['result_bourse_data'] = 'You benefit ' + model['decisionbourse'] + ' scholarship';
            if (model['decisionbourse'] === 'NA') {
              model['result_bourse_data'] = 'You don\'t benefit scholarship.';
            }
            if (model['decisionbourse'] === 'WL') {
              model['result_bourse_data'] = 'You are not selected yet but your application file remains in the selection process.';
            }
            if (model['decisionbourse'] === '--') {
              model['result_bourse_data'] = 'No result yet.';
            }
            if (model['decisionbourse'] === 'TFW') {
              model['result_bourse_data'] = 'You benefit Tuition Fees Waiver scholarship.';
            }
            if (model['decisionbourse'] === 'ISAE-F') {
              model['result_bourse_data'] = 'You benefit ISAE-SUPAERO Foundation scholarship.';
            }
          }
        }
      }
      model['bourse_admission'] = model['decisionbourse'];

      if (model['nationality'] === 'FR' || model['fasia'] === true) {
        model['show_toulbox'] = 'N';
      } else {
        model['show_toulbox'] = 'O';
      }
      if (model['candidat_validation'] !== undefined && model['candidat_validation'] === 'O') {
        model['show_bourses'] = 'O';
        model['show_buttons'] = 'N';
        model['show_buttons_bourses'] = 'O';
        this.desactivateAllfields(schema, ['choice', 'personal', 'background', 'langs', 'fund', 'docs', 'payment', 'validation']);
        this.schemaService.formservice.modifiedSchema.next(schema);
      } else {
        model['show_bourses'] = 'N';
        model['show_buttons'] = 'O';
        model['show_buttons_bourses'] = 'N';
      }
      if (model['bourse_validation'] !== undefined && model['bourse_validation'] === 'O') {
        model['show_buttons_bourses'] = 'N';
        this.desactivateAllfields(schema, ['bourses']);
        this.schemaService.formservice.modifiedSchema.next(schema);
      }

      model['show_buttons_reg'] = 'N';

      if ((model['show_admis'] === 'O' || model['show_admis'] === 'C')
        && model['candidat_confirmation'] !== 'O'
        && model['candidat_confirmation'] !== '--') {
        model['show_buttons_reg'] = 'O';
      } else {
        model['show_buttons_reg'] = 'N';
      }
      if (model['typeformation'] !== undefined && model['typeformation'] === 'ING') {
        model['show_buttons_reg'] = 'N';
      }
      if (model['candidat_confirmation'] !== undefined && model['candidat_confirmation'] === 'O') {
        model['show_buttons_bourses'] = 'N';
        model['show_buttons'] = 'N';
        model['show_buttons_fees'] = 'O';
        this.desactivateAllfields(schema, ['registration', 'funding2', 'parents', 'siblings', 'address', 'documents2', 'register']);
        this.schemaService.formservice.modifiedSchema.next(schema);
      }
      if (model['heb_line'] === undefined || model['heb_line'].length === 0) {
        model['heb_line'] = [{acad_year: '', degree_and_date: '', degree_other: null, field_study: '', institution: ''}];
      }
      if (model['prof_xp'] === undefined || model['prof_xp'].length === 0) {
        model['prof_xp'] = [{company: '', domain_activity: '', from_tod: '', from_tof: '', position: ''}];
      }
      const schols: string[] = this.showOrHideScholarship(
        model['typeformation'],
        model['nationality'],
        model['dual_nationality'],
        model['fstchoice'],
        model['sndchoice'],
        model['trdchoice'],
        model['mscchoice'],
      model['is_european'],
      model['programme']);
      schols.forEach((schol) => model[schol] = 'Y');
      if (schols.length === 0) {
        model['show_buttons_bourses'] = 'N';
        model['no_bourse'] = 'Y';
      }
      // fermeture accès aux candidatures bourses
      if (model['typeformation'] !== undefined && model['typeformation'] === 'ING') {
        model['no_bourse'] = 'Y';
        model['show_bourses'] = 'N';
      }

      // COMMENTER LES 2 LIGNES SUIVANTES POUR OUVRIR LES BOURSES
      // model['show_bourses'] = 'N';
      // model['no_bourse'] = 'Y';

      if (model['confscol'] !== undefined && model['confscol']) {
        model['show_confirm'] = 'O';
      }

      this.schemaService.formservice.modelModified.next(model);
    };
  }

  private prepareDataForCandFileReport(data: any, schema: any): any {
    let newData = JSON.parse(JSON.stringify(data));
    newData['email'] = this.schemaService.authservice.user.login;
    newData['asw_userid'] = newData['email'];
    newData['tf_ctc_name'] = newData['tf_contact_name'];
    newData['tf_ctc_email'] = newData['tf_contact_mail'];
    newData['sbs_ctc_name'] = newData['subs_contact_name'];
    newData['sbs_ctc_email'] = newData['subs_contact_mail'];
    newData = this.translateDegreeCode(newData, schema);
    newData = this.translatePositionCode(newData, schema);
    newData = this.translateSituationCode(newData, schema);
    newData = this.translateFieldStudyCode(newData, schema);
    newData = this.translateTfFundingResCode(newData, schema);
    newData = this.translateSubsFundingResCode(newData, schema);
    newData = this.translateTfAidsTypeCode(newData, schema);
    newData = this.translateSubsAidsTypeCode(newData, schema);
    newData = this.translateFindOutCode(newData, schema);
    return newData;
  }

  private translateFindOutCode(data: any, schema: any): any {
    const newData = JSON.parse(JSON.stringify(data));
    const aidsTypeList = schema.properties['find_out_ISAE']['oneOf'];
    const newList = aidsTypeList.filter((elt) => elt['enum'][0] === newData['find_out_ISAE']);
    if (newList !== undefined && newList.length > 0) {
      newData['find_out'] = newList[0]['description'];
    }
    return newData;
  }

  private translateSubsAidsTypeCode(data: any, schema: any): any {
    const newData = JSON.parse(JSON.stringify(data));
    const aidsTypeList = schema.properties['subs_aids_type']['oneOf'];
    const newList = aidsTypeList.filter((elt) => elt['enum'][0] === newData['subs_aids_type']);
    if (newList !== undefined && newList.length > 0) {
      newData['subs_aids_type'] = newList[0]['description'];
    }
    return newData;
  }

  private translateTfAidsTypeCode(data: any, schema: any): any {
    const newData = JSON.parse(JSON.stringify(data));
    const aidsTypeList = schema.properties['tf_aids_type']['oneOf'];
    const newList = aidsTypeList.filter((elt) => elt['enum'][0] === newData['tf_aids_type']);
    if (newList !== undefined && newList.length > 0) {
      newData['tf_aids_type'] = newList[0]['description'];
    }
    return newData;
  }

  private translateTfFundingResCode(data: any, schema: any): any {
    const newData = JSON.parse(JSON.stringify(data));
    const fundingResList = schema.properties['subs_funding_resources']['oneOf'];
    newData['subs_funding_resources'] = fundingResList
      .filter((elt) => elt['enum'][0] === newData['subs_funding_resources'])
      [0]['description'];
    return newData;
  }

  private translateSubsFundingResCode(data: any, schema: any): any {
    const newData = JSON.parse(JSON.stringify(data));
    const fundingResList = schema.properties['tf_funding_resources']['oneOf'];
    newData['tf_funding_resources'] = fundingResList
      .filter((elt) => elt['enum'][0] === newData['tf_funding_resources'])
      [0]['description'];
    return newData;
  }

  private translateFieldStudyCode(data: any, schema: any): any {
    const newData = JSON.parse(JSON.stringify(data));
    const fieldStudyList = schema.properties['heb_line']['items']['properties']['field_study']['oneOf'];
    newData['heb_line'].forEach((line) => {
      const filteredList = fieldStudyList.filter((elt) => elt['enum'][0] === line['field_study']);
      line['field_study'] = filteredList.length > 0 ? filteredList[0]['description'] : '';
    });
    return newData;
  }

  private translateDegreeCode(data: any, schema: any): any {
    const newData = JSON.parse(JSON.stringify(data));
    const degreeList = schema.properties['heb_line']['items']['properties']['degree_and_date']['oneOf'];
    newData['heb_line'].forEach((line) => {
      if (line['degree_and_date'] !== 'ot') {
        const filteredList = degreeList.filter((elt) => elt['enum'][0] === line['degree_and_date']);
        line['degree_and_date'] = filteredList.length > 0 ? filteredList[0]['description'] : '';
      }
    });
    return newData;
  }

  private translatePositionCode(data: any, schema: any): any {
    const newData = JSON.parse(JSON.stringify(data));
    const positionList = schema.properties['prof_xp']['items']['properties']['position']['oneOf'];
    newData['prof_xp'].forEach((line) => {
      const filteredList = positionList.filter((elt) => elt['enum'][0] === line['position']);
      line['position'] = filteredList.length > 0 ? filteredList[0]['description'] : '';
    });
    return newData;
  }

  private translateSituationCode(data: any, schema: any): any {
    const newData = JSON.parse(JSON.stringify(data));
    const situationList = schema.properties['current_situation']['oneOf'];
    newData['current_situation'] = situationList.filter((elt) => elt['enum'][0] === newData['current_situation'])[0]['description'];
    return newData;
  }

  private getApplicationFile(data: any) {
    this.bos.getApplicationReport(this.schemaService.authservice.user.login, data)
      .subscribe((response: HttpResponse<any>) => this.downloadAction(response, '_ALL.pdf'));
  }

  private downloadAction(response: HttpResponse<any>, filename: string) {
    if (response.status === 200) {
      // `blob` response
      // create `objectURL` of `this.response` : `.pdf` as `Blob`
      const file = window.URL.createObjectURL(response.body);
      const a = document.createElement('a');
      a.href = file;
      a.id = 'dwlLink';
      a.download = (this.schemaService.authservice.user.login + filename);
      document.body.appendChild(a);
      a.click();
      // remove `a` following `Save As` dialog,
      // `window` regains `focus`
      window.onfocus = () => {
        const elt = document.getElementById('dwlLink');
        if (elt !== undefined && elt !== null) {
          document.body.removeChild(elt);
        }
      };
    }
  }

  private getScholApplicationFile(data: any, schema: any) {
    const name = '_' + schema.properties['familyNameValid'].value;
    const firstName = '_' + schema.properties['givenNameValid'].value;
    const type = '_' + AppComponent.getFileType(schema);
    const fileName = name + firstName + type + '.pdf';
    this.bos.getScholApplicationReport(this.schemaService.authservice.user.login, data)
      .subscribe((response: HttpResponse<any>) => this.downloadAction(response, fileName));
  }

  private getRegApplicationFile(data: any) {
    this.bos.getRegApplicationReport(this.schemaService.authservice.user.login, data)
      .subscribe((response: HttpResponse<any>) => this.downloadAction(response, '_register.pdf'));
  }

  private desactivateAllfields(property: any, tabsId: string[]) {
    let listOfProps: string[] = [];
    let fieldsets: any[] = [];
    tabsId.forEach((tabId) => {
      fieldsets = fieldsets.concat(SchemaUtils.getFieldsetsInTab(tabId, property));
      if (tabId === 'docs') {
        this.desactivateArrayFields(property, 'docs');
      }
      if (tabId === 'background') {
        this.desactivateArrayFields(property, 'heb_line');
        this.desactivateArrayFields(property, 'prof_xp');
        this.desactivateArrayFields(property, 'reco_line');
      }
    });
    fieldsets.forEach((fieldset) => listOfProps = listOfProps.concat(fieldset['fields']));

    for (const prop of listOfProps) {
      property.properties[prop]['readOnly'] = true;
    }
  }

  private desactivateArrayFields(property: any, arrayName: string) {
    for (const prop of Object.keys(property.properties[arrayName]['items']['properties'])) {
      property.properties[arrayName]['items']['properties'][prop]['readOnly'] = true;
    }
  }
  private hasCedar(typeform: string,mscchoice: string): boolean {
    let result = false;
    if (mscchoice === 'MAE' && typeform === 'MSC') {
      result = true;
    }
    return result;
  }

  private hasGifas(typeform: string, is_european: string, mscchoice: string): boolean {
    let result = false;
    if (is_european === 'N' || is_european === undefined) {
      if (typeform === 'MSC' && mscchoice === 'MAE' ) {
        result = true;
      }
    }
    return result;
  }

  private hasIsae(typeform: string, nationality: string, dual_nationality: string, mscchoice: string): boolean {
    let result = false;
    if (typeform === 'MSC' && mscchoice === 'MAE') {
      result = true;
    }
    return result;
  }

  private hasTsae(typeform: string, is_european: string, mscchoice: string): boolean {
    let result = false;
    if (is_european === 'O') {
      if (typeform === 'MSC' && mscchoice === 'MAE') {
        result = true;
      }
    }
    return result;
  }

  private hasIsaeExcelSchol(typeform:string, is_european:string, mscchoice:string): boolean {
    let result = false;
    if (is_european === 'N' || is_european === undefined) {
      if (typeform === 'MSC' && mscchoice === 'MAE') {
        result = true;
      }
    }
    return result;
  }

  private hasDga(mscchoice: string, typeform: string): boolean {
    let result = false;
      if (typeform === 'AM') {
        result = true;
      }
    return result;
  }

  private hasIem(programme: string, typeform: string, first: string, second: string, third: string): boolean {
    let result = false;
      if (typeform === 'AM' && (programme === 'MS IEM' || first === 'MS IEM' || second === 'MS IEM' || third === 'MS IEM')) {
        result = true;
      }
    return result;
  }

  private hasScholarshipApplication(typeform: string, mbda: string, gifas: string, cedar: string, isae: string, arise: string, tuition: string, tsae: string) {
    let result = false;
    if (typeform !== 'AM') {
      if (mbda === 'Y' || gifas === 'Y' || cedar === 'Y' || isae === 'Y' || arise === 'Y' || tuition === 'Y' || tsae === 'Y') {
        result = true;
      }
    }
    return result;
  }

  private showOrHideScholarship(
    typeform: string, nationality: string, dual_nationality: string, fstchoice: string, sndchoice: string,
    trdchoice: string, mscchoice: string, is_european: string, programme: string
  ): string[] {
    const scholarshipsToActivate: string[] = [];
    /*
    // CEDAR
    if (this.hasCedar(typeform, mscchoice)) {
      scholarshipsToActivate.push('show_bourse_cedar');
    }
    // GIFAS
    if (this.hasGifas(typeform, is_european, mscchoice)) {
      scholarshipsToActivate.push('show_bourse_gifas');
    }

    // ISAE
    if (this.hasIsae(typeform, nationality, dual_nationality, mscchoice)) {
      scholarshipsToActivate.push('show_bourse_isae');
    }
    // TSAE
    if (this.hasTsae(typeform, is_european, mscchoice)) {
      scholarshipsToActivate.push('show_bourse_tsae');
    }

    if (this.hasIsaeExcelSchol(typeform,is_european,mscchoice)) {
      scholarshipsToActivate.push('show_bourse_isae_excel_schol');
    }

    if (this.hasDga(mscchoice,typeform)) {
      scholarshipsToActivate.push('show_bourse_dga');
    }
    */
    if (this.hasIem(programme,typeform,fstchoice,sndchoice,trdchoice)) {
      scholarshipsToActivate.push('show_bourse_iem');
    }
    return scholarshipsToActivate;
  }
}
