<div class="container">
  <div class="row" *ngIf="statusMessage">
    <div class=" col-sm-12">
      <div class="alert alert-danger">
        {{ statusMessage }}
      </div>
    </div>
  </div>
  <div class="row">
    <div *ngIf="authTypes">
      <div class="col-md-12">

        <div *ngIf="getLiteralAuthType(authTypes) == 'Local'" class="panel panel-default iform-auth-local-container">
          <div class="panel-body">
            <div *ngIf="!localReg">

              <h2>{{ schemaService.getLabel('local_auth_title', 'Vous n\'avez pas de compte ISAE') }} :</h2>
              <p>{{ schemaService.getLabel('local_auth_instruction', 'Connectez-vous') }} </p>
              <div class="row">
                <div class="form-group col-md-6">
                  <label for="inputLogin">Email</label>
                  <input type="email" class="form-control" id="inputLogin" placeholder="Email" [(ngModel)]="inpLogin"/>
                </div>
              </div>
              <div class="row">
                <div class="form-group col-md-6">
                  <label for="inputPassword">Password</label>
                  <input type="password" class="form-control" id="inputPassword" placeholder="Password"
                         [(ngModel)]="inpPassword"/>
                </div>
              </div>
              <button class="btn btn-default"
                      (click)="login(authTypes)">{{ schemaService.getLabel('local_auth', 'Connexion') }}
              </button>
              <button type="button" class="btn btn-link"
                      (click)="passwordForgotten()">{{ schemaService.getLabel('local_auth_forgotten_password', 'Mot de passe oubli&eacute;') }}
              </button>
              <br/>
              <br/>
              <div *ngIf="authNOK" class="row">
                <p class="col alert alert-danger">
                  {{ schemaService.getLabel('local_auth_error', "Erreur d'authentification. Merci de renouveler votre demande") }}
                </p>
              </div>

              <h3>{{ schemaService.getLabel('local_auth_first_connection', 'Première connexion') }}</h3>
              <p>{{ schemaService.getLabel('local_auth_creer_compte_instruction', 'Créez votre compte') }} :</p>
              <button class="btn btn-link"
                      (click)="localRegistration()">{{ schemaService.getLabel('local_auth_creer_compte_bouton', 'Créer') }}
              </button>
              <p>{{ schemaService.getLabel('local_auth_creer_compte_courriel', 'Suivez les instructions du courriel.') }}</p>

            </div>
            <local-registration *ngIf="localReg" [newUser]="!forgotPasswd"></local-registration>
          </div>
        </div>

        <div *ngIf="getLiteralAuthType(authTypes) == 'CAS'" class="panel panel-default iform-auth-cas-container">
          <div class="panel-body">
            <h2>{{ schemaService.getLabel('cas_auth_title', 'Vous avez un compte ISAE') }} :</h2>
            <p>{{ schemaService.getLabel('cas_auth_instruction', 'Connectez-vous') }} :</p>
            <div class="row">
              <div class="col-md-12">
                <button (click)="login(authTypes)" *ngIf="!authService.isLoggedIn"
                        class="btn btn-default">{{ schemaService.getLabel('cas_auth', 'Connexion avec CAS') }}
                </button>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>
