import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';

import {AuthService} from './iform/auth/auth.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';

@Injectable()
export class BackofficeService {

  constructor(private http: HttpClient, private as: AuthService) {
  }

  private _wsurl: string = null;

  get wsurl(): string {
    return this._wsurl;
  }

  set wsurl(myServerws: string) {
    this._wsurl = myServerws;
  }

  public getApplicationReport(userId: string, candData: any): Observable<any> {
    let url: string = this._wsurl + '/report/application_file/' + userId;
    let headers = new HttpHeaders({'Content-Type': 'application/json', 'X-isae-authz': [this.as.user.token]});
    let options: any = {headers, responseType: 'blob', observe: 'response'};
    return this.http.post(url, candData, options);
  }

  public getScholApplicationReport(userId: string, candData: any): Observable<any> {
    let url: string = this._wsurl + '/report/scholapplication_file/' + userId;
    let headers = new HttpHeaders({'Content-Type': 'application/json', 'X-isae-authz': [this.as.user.token]});
    let options: any = {headers, responseType: 'blob', observe: 'response'};
    return this.http.post(url, candData, options);
  }

  public getRegApplicationReport(userId: string, candData: any): Observable<any> {
    let url: string = this._wsurl + '/report/regapplication_file/' + userId;
    let headers = new HttpHeaders({'Content-Type': 'application/json', 'X-isae-authz': [this.as.user.token]});
    let options: any = {headers, responseType: 'blob', observe: 'response'};
    return this.http.post(url, candData, options);
  }

  public validateApplication(): Observable<any> {
    let headers = new HttpHeaders({'Content-Type': 'application/json', 'X-isae-authz': [this.as.user.token]});
    let options: any = {headers};
    return this.http.put(this.wsurl + '/validateapplication/' + this.as.user.login, null, options);
  }

  public validateTuition(): Observable<any> {
    let headers = new HttpHeaders({'Content-Type': 'application/json', 'X-isae-authz': [this.as.user.token]});
    let options: any = {headers};
    return this.http.put(this.wsurl + '/validatetuition/' + this.as.user.login, null, options);
  }

  public validateBourse(): Observable<any> {
    let headers = new HttpHeaders({'Content-Type': 'application/json', 'X-isae-authz': [this.as.user.token]});
    let options: any = {headers};
    return this.http.put(this.wsurl + '/validatescholarship/' + this.as.user.login, null, options);
  }

  public validateRegistration(): Observable<any> {
    let headers = new HttpHeaders({'Content-Type': 'application/json', 'X-isae-authz': [this.as.user.token]});
    let options: any = {headers};
    return this.http.put(this.wsurl + '/validateregistration/' + this.as.user.login, null, options);
  }

  public getPays(): Observable<any> {
    let url: string = this._wsurl + '/pays';
    let headers = new HttpHeaders({'Content-Type': 'application/json', 'X-isae-authz': [this.as.user.token]});
    let options: any = {headers};
    return this.http.get(url, options);
  }

  public getJurys(): Observable<any> {
    let url: string = this._wsurl + '/jurys';
    let headers = new HttpHeaders({'Content-Type': 'application/json', 'X-isae-authz': [this.as.user.token]});
    let options: any = {headers};
    return this.http.get(url, options);
  }

  public getFormationsType(codetf): Observable<any> {
    let url: string = this._wsurl + '/iformformations/' + codetf;
    let headers = new HttpHeaders({'Content-Type': 'application/json', 'X-isae-authz': [this.as.user.token]});
    let options: any = {headers};
    return this.http.get(url, options);
  }

  public getFormations(): Observable<any> {
    let url: string = this._wsurl + '/iformformations/AM';
    let headers = new HttpHeaders({'Content-Type': 'application/json', 'X-isae-authz': [this.as.user.token]});
    let options: any = {headers};
    return this.http.get(url, options);
  }

  public getInternationalInstitutions(codePays): Observable<any> {
    if (codePays) {
      let url: string = this._wsurl + '/institutions/' + codePays;
      let headers = new HttpHeaders({'Content-Type': 'application/json', 'X-isae-authz': [this.as.user.token]});
      let options: any = {headers};
      return this.http.get(url, options);
    } else {
      return of();
    }
  }

  public getCommuneDept(dept: String): Observable<any> {
    if(dept){
      let url: string = this._wsurl + '/communedept/' + dept;
      let headers = new HttpHeaders({'Content-Type': 'application/json', 'X-isae-authz': [this.as.user.token]});
      let options: any = {headers};
      return this.http.get(url, options);
    }else {
      return of();
    }
  }

  public getCommuneZip(zip: String): Observable<any> {
    if(zip){
      let url: string = this._wsurl + '/commune/' + zip;
      let headers = new HttpHeaders({'Content-Type': 'application/json', 'X-isae-authz': [this.as.user.token]});
      let options: any = {headers};
      return this.http.get(url, options);
    }else{
      return of()
    }
    
  }
}
