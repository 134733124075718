import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule, DatePipe} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {FormElementComponent} from './formelement.component';
import {FormComponent} from './form.component';
import {WidgetChooserComponent} from './widgetchooser.component';
import {WidgetRegistry} from './widgetregistry';
import {
  ArrayWidget,
  CheckboxWidget,
  DateWidget,
  DefaultWidgetRegistry,
  FileWidget,
  HelpWidget,
  IntegerWidget,
  ObjectWidget,
  RadioWidget,
  RangeWidget,
  SelectWidget,
  SelectWidget2,
  StringWidget,
  TextAreaWidget
} from './defaultwidgets';
import {DefaultWidget} from './default.widget';

import {DatepickerModule} from 'ngx-bootstrap/datepicker';
import {TabsModule} from 'ngx-bootstrap/tabs';
import {TooltipModule} from 'ngx-bootstrap/tooltip';

import {FileUploadModule} from 'ng2-file-upload';
import {SchemaValidatorFactory, ZSchemaValidatorFactory} from './schemavalidatorfactory';
import {NgSelectModule} from '@ng-select/ng-select';

@NgModule({
  imports: [TooltipModule.forRoot(), DatepickerModule.forRoot(), TabsModule.forRoot(),
    CommonModule, FormsModule, ReactiveFormsModule, FileUploadModule, NgSelectModule],
  declarations: [
    FormElementComponent,
    FormComponent,
    WidgetChooserComponent,
    DefaultWidget,
    ArrayWidget,
    ObjectWidget,
    CheckboxWidget,
    FileWidget,
    IntegerWidget,
    TextAreaWidget,
    RadioWidget,
    RangeWidget,
    SelectWidget,
    SelectWidget2,
    StringWidget,
    HelpWidget,
    DateWidget
  ],
  entryComponents: [
    FormElementComponent,
    FormComponent,
    WidgetChooserComponent,
    ArrayWidget,
    ObjectWidget,
    CheckboxWidget,
    FileWidget,
    IntegerWidget,
    TextAreaWidget,
    RadioWidget,
    RangeWidget,
    SelectWidget,
    SelectWidget2,
    StringWidget,
    HelpWidget,
    DateWidget
  ],
  exports: [
    FormComponent,
    FormElementComponent,
    WidgetChooserComponent,
    ArrayWidget,
    ObjectWidget,
    CheckboxWidget,
    FileWidget,
    IntegerWidget,
    TextAreaWidget,
    RadioWidget,
    RangeWidget,
    SelectWidget,
    SelectWidget2,
    StringWidget,
    HelpWidget,
    DateWidget
  ],
  providers: [DatePipe]
})
export class SchemaFormModule {

  static forRoot(): ModuleWithProviders<SchemaFormModule> {
    return {
      ngModule: SchemaFormModule,
      providers: [
        {
          provide: WidgetRegistry,
          useClass: DefaultWidgetRegistry
        },
        {
          provide: SchemaValidatorFactory,
          useClass: ZSchemaValidatorFactory
        }
      ]
    };
  }

}
