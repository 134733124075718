import {Component, OnInit} from '@angular/core';

import {ControlWidget} from '../../widget';

@Component({
  selector: 'sf-select-widget',
  template: `
    <div class="widget form-group" [class.has-error]="!formProperty.valid">
      <div [class]="formProperty.schema.htmlClass">
        <label [attr.for]="id" class="horizontal control-label">
          {{ schema.title }}
        </label>
        <ng-select [formControl]="control" [bindLabel]="'description'" [items]="schema.oneOf">
        </ng-select>
        <input *ngIf="schema.readOnly" [attr.name]="name" type="hidden" [formControl]="control">
        <span *ngIf="schema.description" class="formHelp">{{ schema.description }}</span>
      </div>
    </div>`
})

export class SelectWidget2 extends ControlWidget implements OnInit {

  enumCompare = (a, b) => a.enum === b;
  // Workaround to enable angular to detect that we are dealing with an array
  // Necessary because the schema is loaded asynchronously
  // because of external references
  public transform(value) {
    if (value !== undefined) {
      return Array.from(value);
    } else {
      return value;
    }
  }


  ngAfterViewInit() {
    let self = this;
    let control = this.control;
    this.formProperty.valueChanges.subscribe((newValue) => {

      control.setValue(self.formProperty.schema.oneOf.find(o => o.enum[0] === newValue), {emitEvent: false});

    });
    control.valueChanges.subscribe((newValue) => {
      if (newValue) {
        this.formProperty.setValue(newValue.enum[0], false);
      } else {
        this.formProperty.setValue('-', false);
      }
    });
    this.formProperty.errorsChanges.subscribe((errors) => {
      control.setErrors(errors, {emitEvent: true});
    });
  }

  ngOnInit(): void {

  }
}
