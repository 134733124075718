import {Injectable} from '@angular/core';
import {FormSchemaService} from '../iform/form-schema.service';
import {BackofficeService} from '../backoffice.service';
import {SchemaUtils} from '../iform/utils/schema-utils';

@Injectable()
export class TabService {
  constructor(
    protected scService: FormSchemaService, protected bos: BackofficeService
  ) {

    // Calcul de la liste des villes de naissance
    this.scService.options.setVilleNaissanceSchema = setTimeout((deptBirth, formProperty) => {
      if (deptBirth && formProperty) {
        SchemaUtils.updateOneOf(formProperty, bos.getCommuneDept(deptBirth));
      }
    }, 5);

    // Calcul de la liste des villes adresse rosalie
    this.scService.options.setVilleAdresseSchema = setTimeout((zip, formProperty) => {
      if (zip && formProperty) {
        SchemaUtils.updateOneOf(formProperty, bos.getCommuneZip(zip));
      }
      ;
    }, 5);
  }

}
