import {concat as observableConcat, Observable} from 'rxjs';
import {Injectable} from '@angular/core';

import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';

@Injectable()
export class RefsLoaderService {

  constructor(private http: HttpClient) {
  }

  public resolveExternalRefs(url: string) {
    return this.http.get(url).pipe(map((res) => res));
  }

  // Derefence $ref urls in jsonSchema.
  // Return an observable of objects {path: xx, val: xx}
  // where path : path to the $ref in the schema
  // val : resulting values of the dereferencing
  public dereference(jsonSchema: any) {
    let derefs = this.findRefs(jsonSchema);
    let obs: Observable<any>;
    derefs.forEach((entry) => {
      if (obs === undefined || obs === null) {
        obs = this.resolveExternalRefs(entry.url).pipe(map((res) => {
          return {path: entry.path, val: res};
        }));
      } else {
        obs = observableConcat(obs, this.resolveExternalRefs(entry.url).pipe(map((res) => {
          return {path: entry.path, val: res};
        })));
      }
    });
    return obs;
  }

  private findRefs(jsonSchema: any, key?: string, path?: string): { path: string, url: string }[] {
    let refs: { path: string, url: string }[] = [];
    if (path === undefined || path === null) {
      path = '/';
    } else {
      path = (path == '/' ? '/' + key : path + '/' + key);
    }
    for (let key in jsonSchema) {
      if (key == '$ref') {
        refs.push({path: path, url: jsonSchema[key]});
        break;
      } else if (typeof (jsonSchema[key]) == 'object') {
        refs = refs.concat(this.findRefs(jsonSchema[key], key, path));
      }
    }
    return refs;
  }

}
