import {Injectable} from '@angular/core';

import {Observable, Observer} from 'rxjs';


import {Params, Router} from '@angular/router';
import {AuthType} from './auth-provider-factory';
import {HttpClient, HttpHeaders} from '@angular/common/http';

@Injectable()
export class AuthService {
  isLoggedIn: boolean = false;
  user: any;
  // store the URL so we can redirect after logging in
  redirectUrl: string;

  constructor(private http: HttpClient, public router: Router) {
    this.initUser();
    // cet utilisateur est il en train d'interpréter le rôle d'un autre utilisateur
  }

  private _wsurl: string = null;

  get wsurl(): string {
    return this._wsurl;
  }

  set wsurl(myServerws: string) {
    this._wsurl = myServerws;
  }

  private _baseurl: string = null;

  get baseurl(): string {
    return this._baseurl;
  }

  set baseurl(myServerbase: string) {
    this._baseurl = myServerbase;
  }

  private _formid: string = null;

  get formid(): string {
    return this._formid;
  }

  set formid(myFormid: string) {
    this._formid = myFormid;
  }

  private _webappurl: string = null;

  get webappurl(): string {
    return this._webappurl;
  }

  set webappurl(webappurl: string) {
    this._webappurl = webappurl;
  }

  get isAdmin() {
    return this.user.isAdmin;
  }

  login(at: AuthType, login?: string, password?: string): Observable<boolean> {
    let url = '';
    let headers = new HttpHeaders({'Content-Type': 'application/json'});
    let options: any = {headers};
    let data = {'login': login || '', 'password': password || ''};
    let retourObserver: Observer<boolean>;
    let retour = new Observable<boolean>(observer => retourObserver = observer);

    switch (at) {
    case AuthType.cas:
      url = this._wsurl + '/' + this._formid + '/logincas';
      var datacas = {'page_url': window.location.href};

      options = {headers: headers, withCredentials: true};
      this.http.post(url, datacas, options).subscribe((response: any) => {
        let dt = response;
        if (dt.result == 'error') {
          window.location.href = this._baseurl + '/' + 'cas-login';
        } else if (dt.result == 'ok') {
          this.user.login = dt.login;
          this.user.email = dt.email;
          this.user.token = dt.token;
          this.user.isAdmin = dt.admin;
          this.isLoggedIn = true;
          retourObserver.next(true);
        }
      });
      break;
    case AuthType.local:
      url = this._wsurl + '/' + this._formid + '/login';
      this.http.post(url, data, options).subscribe((response: any) => {
        let dt = response;
        if (dt.result == 'ok') {
          this.user.login = login;
          this.user.email = login;
          this.user.token = dt.token;
          this.user.isAdmin = dt.admin;
          this.isLoggedIn = true;
          retourObserver.next(true);
        } else if (dt.result == 'error') {
          retourObserver.next(false);
        }
      });
      break;
    }
    return retour;
  }

  // force le statut authentifié au niveau du client si certains paramètres sont remplis au niveau de l'url
  authenticateWithRouteParams(params: Params, redirectRoute) {
    if (params['login']) {
      this.user.login = params['login'];
      this.user.email = params['email'];
      this.user.isAdmin = Number(params['admin']);
      this.user.token = params['token'];
      this.isLoggedIn = true;
      this.router.navigate(redirectRoute);
    }
  }

  logout(): void {
    this.isLoggedIn = false;
    this.initUser();
    let url = this._wsurl.replace('/form', '') + '/logout';
    let options = {withCredentials: true};
    this.http.post(url, {}, options).toPromise();
  }

  registerUser(login: string): Observable<any> {
    let url = this._wsurl + '/' + this._formid + '/user/register';
    let data = {'user': login, 'appurl': this.webappurl};
    let headers = new HttpHeaders({'Content-Type': 'application/json'});
    let options = {headers};
    return this.http.post(url, data, options);
  }

  askResetPwdLink(login: string): Observable<any> {
    let url = this._wsurl + '/' + this._formid + '/user/askreset';
    let data = {'user': login, 'appurl': this.webappurl};
    let headers = new HttpHeaders({'Content-Type': 'application/json'});
    let options = {headers};
    return this.http.post(url, data, options);
  }

  activateUser(formid: string, login: string, password: string, token: string): Observable<any> {
    let url = this._wsurl + '/' + formid + '/user/activate';
    let data = {'user': login, 'password': password, 'token': token};
    let headers = new HttpHeaders({'Content-Type': 'application/json'});
    let options = {headers};
    return this.http.post(url, data, options);
  }

  resetUser(formid: string, login: string, password: string, token: string): Observable<any> {
    let url = this._wsurl + '/' + formid + '/user/reset';
    let data = {'user': login, 'password': password, 'token': token};
    let headers = new HttpHeaders({'Content-Type': 'application/json'});
    let options = {headers};
    return this.http.post(url, data, options);
  }

  private initUser() {
    this.user = {};
    this.user.login = null;
    this.user.email = null;
    this.user.token = null;
    this.user.isAdmin = 0;
  }

}
