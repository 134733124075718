import {switchMap} from 'rxjs/operators';
import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {AuthService} from './auth.service';
import {AuthType} from './auth-provider-factory';
import {FormSchemaService} from '../form-schema.service';

@Component({
  templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit {
  authTypes: AuthType;
  message: string;
  public inpLogin: string = '';
  public inpPassword: string = '';
  public localReg: boolean = false;
  public forgotPasswd: boolean = false;
  authNOK: boolean = false;
  public statusMessage: string;

  constructor(public schemaService: FormSchemaService, public authService: AuthService, public router: Router, private route: ActivatedRoute) {
    this.setMessage();
  }

  ngOnInit(): void {
    this.route.params.pipe(
      switchMap((params: Params) => this.schemaService.getFormAuthTypes()))
      .subscribe((at: AuthType) => {
        this.authTypes = at;
      });

    this.route.params.subscribe((params: Params) => {
      this.authService.authenticateWithRouteParams(params, ['/form', this.schemaService.formid]);
      if (params['message']) {
        this.statusMessage = params['message'];
      }
    });

  }

  localRegistration(): void {
    this.localReg = true;
  }

  passwordForgotten(): void {
    this.localReg = true;
    this.forgotPasswd = true;
  }

  setMessage() {
    this.message = 'Logged ' + (this.authService.isLoggedIn ? 'in' : 'out');
  }

  login(at: AuthType) {
    this.message = 'Trying to log in ...';
    switch (at) {
    case AuthType.cas:
      console.log('CAS');
      break;
    case AuthType.local:
      console.log('Local');
    }
    this.authService.login(at, this.inpLogin, this.inpPassword).subscribe(() => {
      this.setMessage();
      if (this.authService.isLoggedIn) {
        this.authNOK = false;
        // Get the redirect URL from our auth service
        // If no redirect has been set, use the default
        let redirect = this.authService.redirectUrl ? this.authService.redirectUrl : '/';
        // Redirect the user
        this.router.navigate([redirect]);
      } else {
        this.authNOK = true;
        this.message = 'Erreur d\'authentification';
      }
    });
  }

  logout() {
    this.authService.logout();
    this.setMessage();
  }

  getLiteralAuthType(at: AuthType): string {
    let retour = '';
    switch (at) {
    case AuthType.cas:
      retour = 'CAS';
      break;
    case AuthType.local:
      retour = 'Local';
      break;
    }
    return retour;
  }
}
