import {ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';

import {Action, ActionRegistry, FormProperty, FormPropertyFactory, SchemaPreprocessor, Validator, ValidatorRegistry} from './model';

import {SchemaValidatorFactory, ZSchemaValidatorFactory} from './schemavalidatorfactory';
import {WidgetFactory} from './widgetfactory';
import {TerminatorService} from './terminator.service';

import {RefsLoaderService} from './model/refs-loader.service';

export function useFactory(schemaValidatorFactory, validatorRegistry) {
  return new FormPropertyFactory(schemaValidatorFactory, validatorRegistry);
}

@Component({
  selector: 'sf-form',
  template: `
    <form>
      <sf-form-element
        *ngIf="rootProperty" [formProperty]="rootProperty"></sf-form-element>
    </form>`,
  providers: [RefsLoaderService,
    ActionRegistry,
    ValidatorRegistry,
    SchemaPreprocessor,
    WidgetFactory,
    {
      provide: SchemaValidatorFactory,
      useClass: ZSchemaValidatorFactory
    }, {
      provide: FormPropertyFactory,
      useFactory: useFactory,
      deps: [SchemaValidatorFactory, ValidatorRegistry]
    },
    TerminatorService,
  ]
})
export class FormComponent implements OnChanges {

  @Input() schema: any = null;

  @Input() model: any;

  @Input() actions: { [actionId: string]: Action } = {};

  @Input() validators: { [path: string]: Validator } = {};

  @Output() onChange = new EventEmitter<{ value: any, property: any }>();

  @Output() onInit = new EventEmitter<{ value: any, property: any }>();

  @Input() options: any = null;

  rootProperty: FormProperty = null;

  constructor(
    private formPropertyFactory: FormPropertyFactory,
    private actionRegistry: ActionRegistry,
    private validatorRegistry: ValidatorRegistry,
    private cdr: ChangeDetectorRef,
    private rls: RefsLoaderService,
    private terminator: TerminatorService
  ) {
  }

  ngOnChanges(changes: any) {
    let obs = this.rls.dereference(this.schema);
    if (obs !== undefined && obs !== null) {
      obs.subscribe((val) => {
        let obj = this.schema;
        let keys = val.path.split('/');
        let i = 1;
        for (i = 1; i < keys.length - 1; i++) {
          obj = obj[keys[i]];
        }
        obj[keys[i]] = val.val;
      });
    }

    if (changes.validators) {
      this.setValidators();
    }

    if (changes.actions) {
      this.setActions();
    }

    if (this.schema && !this.schema.type) {
      this.schema.type = 'object';
    }

    if (this.schema && changes.schema) {
      if (!changes.schema.firstChange) {
        this.terminator.destroy();
      }
      SchemaPreprocessor.preprocess(this.schema);
      this.rootProperty = this.formPropertyFactory.createProperty(this.schema);
      this.rootProperty.valueChanges.subscribe(value => {
        this.onChange.emit({value: value, property: this.rootProperty});
      });
      if (this.options !== undefined) {
        this.rootProperty.options = this.options;
      }
    }

    if (changes.options && this.options !== undefined) {
      this.rootProperty.options = this.options;
    }

    if (this.schema && (changes.model || changes.schema)) {
      this.rootProperty.reset(this.model, false);
      this.onInit.emit({value: changes.model.currentValue, property: this.rootProperty});
      this.cdr.detectChanges();
    }
  }

  private setValidators() {
    this.validatorRegistry.clear();
    if (this.validators) {
      for (let validatorId in this.validators) {
        if (this.validators.hasOwnProperty(validatorId)) {
          this.validatorRegistry.register(validatorId, this.validators[validatorId]);
        }
      }
    }
  }

  private setActions() {
    this.actionRegistry.clear();
    if (this.actions) {
      for (let actionId in this.actions) {
        if (this.actions.hasOwnProperty(actionId)) {
          this.actionRegistry.register(actionId, this.actions[actionId]);
        }
      }
    }
  }

  public reset() {
    this.rootProperty.reset(null, true);
  }
}
