import {
  ArrayWidget,
  CheckboxWidget,
  DateWidget,
  FileWidget,
  HelpWidget,
  IntegerWidget,
  ObjectWidget,
  RadioWidget,
  RangeWidget,
  SelectWidget,
  SelectWidget2,
  StringWidget,
  TextAreaWidget
} from './';

import {WidgetRegistry} from '../widgetregistry';
import {Injectable} from '@angular/core';

@Injectable()
export class DefaultWidgetRegistry extends WidgetRegistry {
  constructor() {
    super();

    this.register('array', ArrayWidget);
    this.register('object', ObjectWidget);

    this.register('string', StringWidget);
    this.register('search', StringWidget);
    this.register('tel', StringWidget);
    this.register('url', StringWidget);
    this.register('email', StringWidget);
    this.register('password', StringWidget);
    this.register('color', StringWidget);
    this.register('date', StringWidget);
    this.register('date-time', StringWidget);
    this.register('time', StringWidget);

    this.register('integer', IntegerWidget);
    this.register('number', IntegerWidget);
    this.register('range', RangeWidget);

    this.register('textarea', TextAreaWidget);

    this.register('file', FileWidget);
    this.register('select', SelectWidget);
    this.register('select2', SelectWidget2);
    this.register('radio', RadioWidget);
    this.register('boolean', CheckboxWidget);
    this.register('checkbox', CheckboxWidget);
    this.register('help', HelpWidget);
    this.register('datepicker', DateWidget);

    this.setDefaultWidget(StringWidget);
  }
}
