import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {ReactiveFormsModule} from '@angular/forms';

import {AppComponent} from './app.component';

import {AppRoutingModule} from './app-routing.module';

import {HomeComponent} from './home.component';
import {PageNotFoundComponent} from './page-not-found.component';

import {IFormModule} from './iform/iform.module';
import {IFormConfService} from './iform/iformconf.service';
import {BackofficeService} from './backoffice.service';
import {TabService} from './services/tab.service';
import {HttpClientModule} from '@angular/common/http';

@NgModule({
  imports: [BrowserModule, ReactiveFormsModule, HttpClientModule, IFormModule, AppRoutingModule],
  declarations: [AppComponent, HomeComponent, PageNotFoundComponent],
  bootstrap: [AppComponent],
  providers: [IFormConfService, BackofficeService, TabService]
})
export class AppModule {
  constructor() {

  }
}
