import {BehaviorSubject, combineLatest as observableCombineLatest, Observable} from 'rxjs';

import {distinctUntilChanged, map} from 'rxjs/operators';

import {SchemaValidatorFactory, ZSchemaValidatorFactory} from '../schemavalidatorfactory';
import {ValidatorRegistry} from './validatorregistry';

export abstract class FormProperty {
  public schemaValidator: Function;
  public required: boolean;

  _value: any = null;
  _errors: any = null;
  private _valueChanges = new BehaviorSubject<any>(null);
  private _errorsChanges = new BehaviorSubject<any>(null);
  private _visible = true;
  private _visibilityChanges = new BehaviorSubject<boolean>(true);
  private _readOnly = false;
  private _readOnlyChanges = new BehaviorSubject<boolean>(true);
  private _root: PropertyGroup;
  private _parent: PropertyGroup;
  private _path: string;
  protected _submitable: boolean = true;

  public options: any;

  constructor(
    schemaValidatorFactory: SchemaValidatorFactory,
    private validatorRegistry: ValidatorRegistry,
    public schema: any,
    parent: PropertyGroup,
    path: string
  ) {
    this.schemaValidator = schemaValidatorFactory.createValidatorFn(this.schema);

    this._parent = parent;
    if (parent) {
      this._root = parent.root;
    } else if (this instanceof PropertyGroup) {
      this._root = <PropertyGroup> <any> this;
    }
    this._path = path;
  }

  public get submitable(): boolean {
    return this._submitable;
  }

  public get valueChanges() {
    return this._valueChanges;
  }

  public get errorsChanges() {
    return this._errorsChanges;
  }

  public get type(): string {
    return this.schema.type;
  }

  public get parent(): PropertyGroup {
    return this._parent;
  }

  public get root(): PropertyGroup {
    return this._root || <PropertyGroup> <any> this;
  }

  public get path(): string {
    return this._path;
  }

  public get value() {
    return this._value;
  }

  public get visible() {
    return this._visible;
  }

  public get readOnly() {
    return this._readOnly;
  }

  public get valid() {
    return this._errors === null;
  }

  public abstract setValue(value: any, onlySelf: boolean);

  public abstract reset(value: any, onlySelf: boolean)

  public updateValueAndValidity(onlySelf = false, emitEvent = true) {
    this._updateValue();

    if (emitEvent) {
      this.valueChanges.next(this.value);
    }

    this._runValidation();

    if (this.parent && !onlySelf) {
      this.parent.updateValueAndValidity(onlySelf, emitEvent);
    }

  }

  private requireValidator = (value, prop, form) => {
    let errors = [];
    if (value === null || value == '') {
      errors[0] = {};
      errors[0][this.path] = {'expectedValue': 'should not be empty'};
    }

    return errors;
  };

  /**
   *  @internal
   */
  public abstract _updateValue();

  /**
   * @internal
   */
  public _runValidation(): any {
    // creating custom validator if we dont want to use the validators cache
    if (this.schema.nocache === true) {
      this.schemaValidator = new ZSchemaValidatorFactory().createValidatorFn(this.schema);
    }
    let errors = this.schemaValidator(this._value) || [];
    let customValidator = this.validatorRegistry.get(this.path);
    if (customValidator) {
      let customErrors = customValidator(this.value, this, this.findRoot());
      errors = this.mergeErrors(errors, customErrors);
    }
    if (this.required && this.visible) {
      let requiredErrors = this.requireValidator(this.value, this, this.findRoot());
      errors = this.mergeErrors(errors, requiredErrors);
    }
    if (errors.length === 0) {
      errors = null;
    }

    this._errors = errors;
    this.setErrors(this._errors);
  }

  private mergeErrors(errors, newErrors) {
    if (newErrors) {
      if (Array.isArray(newErrors)) {
        errors = errors.concat(...newErrors);
      } else {
        errors.push(newErrors);
      }
    }
    return errors;
  }

  private setErrors(errors) {
    this._errors = errors;
    this._errorsChanges.next(errors);
  }

  searchProperty(path: string): FormProperty {
    let prop: FormProperty = this;
    let base: PropertyGroup = null;

    let result = null;
    if (path[0] === '/') {
      base = this.findRoot();
      result = base.getProperty(path.substr(1));
    } else {
      while (result === null && prop.parent !== null) {
        prop = base = prop.parent;
        result = base.getProperty(path);
      }
    }
    return result;
  }

  public findRoot(): PropertyGroup {
    let property: FormProperty = this;
    while (property.parent !== null) {
      property = property.parent;
    }
    return <PropertyGroup> property;
  }

  private setVisible(visible: boolean) {
    this._visible = visible;
    this._visibilityChanges.next(visible);
    this.updateValueAndValidity();
    if (this.parent) {
      this.parent.updateValueAndValidity(false, true);
    }
  }

  private setReadOnly(readOnly: boolean) {
    this._readOnly = readOnly;
    this._readOnlyChanges.next(readOnly);
  }

  //public checkVisibility
  public dependencyValueCheck(value, definition) {
    for (let i in definition) {
      let controlval = definition[i];
      if (controlval == value) {
        return true;
      }
      if (controlval.startsWith('$ANY$')) {
        return value !== undefined && value.length > 0;
      }
      if (controlval.startsWith('$NOT$')) {
        let realcontrolval = controlval.substring(5);
        if (String(realcontrolval) !== String(value)) {
          return true;
        }
      }
    }
    return false;
  }

  private _bindGeneric(definitions, sourceObservableName, mapFunction) {
    if (definitions !== undefined) {
      let propertiesBinding = [];
      for (let dependencyPath in definitions) {
        if (definitions.hasOwnProperty(dependencyPath)) {
          let property = this.searchProperty(dependencyPath);
          if (property) {
            // Using a mapper to latter use propertiesbinding
            if (sourceObservableName !== undefined) {
              let valueCheck = property.valueChanges.pipe(map(value => mapFunction(value, dependencyPath)));
              let sourceCheck = property[sourceObservableName];
              let and = observableCombineLatest([valueCheck, sourceCheck], (v1, v2) => v1 && v2);
              propertiesBinding.push(and);
            }
            // Directly infering with the sourceobervable
            else {
              let valueCheck = property.valueChanges.subscribe(value => mapFunction(value, dependencyPath));
            }
          } else {
            console.warn('Can\'t find property ' + dependencyPath + ' for ' + sourceObservableName + ' check of ' + this.path);
          }
        }
      }
      return propertiesBinding;
    }
  }

  public _bindGenericWithSetter(definitions, sourceObservableName, targetFunction, targetDefault, mapFunction) {

    if (typeof definitions === 'object' && Object.keys(definitions).length === 0) {
      this[targetFunction](targetDefault);
    }

    let propertiesBinding = this._bindGeneric(definitions, sourceObservableName, mapFunction);

    if (propertiesBinding !== undefined) {
      observableCombineLatest(propertiesBinding, (...values: boolean[]) => {
        return values.indexOf(true) !== -1;
      }).pipe(distinctUntilChanged()).subscribe((value) => {
        this[targetFunction](value);
      });
    }
  }

  // A field is visible if AT LEAST ONE of the properties it depends on is visible AND has a value in the list
  public _bindVisibility() {
    return this._bindGenericWithSetter(this.schema.visibleIf,
      '_visibilityChanges',
      'setVisible',
      false,
      (value, dependencyPath) => this.dependencyValueCheck(value, this.schema.visibleIf[dependencyPath]));
  }

  public _bindReadOnly() {
    return this._bindGenericWithSetter(this.schema.readOnlyIf,
      '_readOnlyChanges',
      'setReadOnly',
      true,
      (value, dependencyPath) => this.dependencyValueCheck(value, this.schema.readOnlyIf[dependencyPath]));
  }

  // The value is checked for update when one of the dependencies changes
  public _bindValue() {
    this._bindGeneric(this.schema.bindValue,
      undefined,
      (value, dependencyPath) => {
        let options = this.root.options;
        let functiontocall = this.schema.bindValue[dependencyPath];
        if (options !== undefined) {
          if (options[functiontocall] !== undefined) {
            var result = options[functiontocall](value, this.root);
            if (result === undefined) {
            } else if (result instanceof Observable) {
              result.subscribe((value) => this.setValue(value, false));
            } else {
              this.setValue(result, false);
            }
          } else {
            console.warn('Can\'t find function ' + functiontocall + ' for value binding of ' + this.path);
          }
        }
      });
  }

  // The value is checked for update when one of the dependencies changes
  public _bindAny() {
    this._bindGeneric(this.schema.bind,
      undefined,
      (value, dependencyPath) => {
        let options = this.root.options;
        let definition = this.schema.bind[dependencyPath];
        let functiontocall = definition;
        if (options !== undefined) {
          if (options[functiontocall] !== undefined) {
            options[functiontocall](value, this);
          } else {
            console.warn('Can\'t find function ' + functiontocall + ' for binding of ' + this.path);
          }
        }
      });
  }

  public setSchema(schema) {
    this.schema = schema;
    this.schemaValidator = new ZSchemaValidatorFactory().createValidatorFn(this.schema);
    this.updateValueAndValidity();
  }
}

export abstract class PropertyGroup extends FormProperty {

  properties: FormProperty[] | { [key: string]: FormProperty } = null;

  getProperty(path: string) {
    let subPathIdx = path.indexOf('/');
    let propertyId = subPathIdx !== -1 ? path.substr(0, subPathIdx) : path;

    let property = this.properties[propertyId];
    if (property !== null && subPathIdx !== -1 && property instanceof PropertyGroup) {
      let subPath = path.substr(subPathIdx + 1);
      property = (<PropertyGroup> property).getProperty(subPath);
    }
    return property;
  }

  public getFieldset(fieldId: string): any {
    return this.schema.fieldsets.find(x => x.id == fieldId);
  }

  public forEachChild(fn: (formProperty: FormProperty, str: String) => void) {
    for (let propertyId in this.properties) {
      if (this.properties.hasOwnProperty(propertyId)) {
        let property = this.properties[propertyId];
        fn(property, propertyId);
      }
    }
  }

  public forEachChildRecursive(fn: (formProperty: FormProperty) => void) {
    this.forEachChild((child) => {
      fn(child);
      if (child instanceof PropertyGroup) {
        (<PropertyGroup> child).forEachChildRecursive(fn);
      }
    });
  }

  public _bindVisibility() {
    super._bindVisibility();
    this._bindVisibilityRecursive();
  }

  private _bindVisibilityRecursive() {
    this.forEachChildRecursive((property) => {
      property._bindVisibility();
    });
  }

  public _bindReadOnly() {
    super._bindReadOnly();
    this._bindReadOnlyRecursive();
  }

  private _bindReadOnlyRecursive() {
    this.forEachChildRecursive((property) => {
      property._bindReadOnly();
    });
  }

  public _bindValue() {
    super._bindValue();
    this._bindValueRecursive();
  }

  private _bindValueRecursive() {
    this.forEachChildRecursive((property) => {
      property._bindValue();
    });
  }

  public _bindAny() {
    super._bindAny();
    this._bindAnyRecursive();
  }

  private _bindAnyRecursive() {
    this.forEachChildRecursive((property) => {
      property._bindAny();
    });
  }

  public isRoot() {
    return this === this.root;
  }
}
