export class SchemaUtils {

  /*
  * Retourne l'onglet qui contient le champ en paramètre
  */
  public static getTab(property: any): any {
    let mytab: any = {};
    let tabs = property.root.schema.tabs;
    let fieldset = this.getFieldSet(property);
    let i = 0;
    let trouve: boolean = false;
    while (!trouve && i < tabs.length) {
      if (tabs[i].fieldsets.indexOf(fieldset.id) > -1) {
        mytab = tabs[i];
        trouve = true;
      }
      ++i;
    }
    return mytab;
  }

  public static getTabFromTabId(tabId: string, rootProperty: any): any {
    let tabs: any = rootProperty.schema.tabs;
    let trouve: boolean = false;
    let i: number = 0;
    let tab = {};
    while (!trouve && i < tabs.length) {
      if (tabs[i].id == tabId) {
        tab = tabs[i];
        trouve = true;
      }
      ++i;
    }
    return tab;
  }

  public static getTabIndexFromTabId(tabId: string, rootProperty: any): any {
    let tabs: any = rootProperty.schema.tabs;
    let trouve: boolean = false;
    let i: number = 0;
    let index: number = -1;
    while (!trouve && i < tabs.length) {
      if (tabs[i].id == tabId) {
        index = i;
        trouve = true;
      }
      ++i;
    }
    return index;
  }

  /*
  * Retourne le fieldset qui contient le champ en paramètre
  */
  public static getFieldSet(property: any): any {
    let myfs: any = {};
    let fieldsets = property.root.schema.fieldsets;
    let i = 0;
    let trouve: boolean = false;
    while (!trouve && i < fieldsets.length) {
      let j = 0;
      while (!trouve && j < fieldsets[i].fields.length) {
        if (property.root.properties[fieldsets[i].fields[j]] == property) {
          myfs = fieldsets[i];
          trouve = true;
        }
        ++j;
      }
      ++i;
    }
    return myfs;
  }

  /*
  * Retourne la liste des fieldsets du schéma en paramètre contenus dans l'onglet
  * dont l'id est passé en paramètre
  */
  public static getFieldsetsInTab(tabId: string, schema: any): any {
    let results: any = [];
    let tabs = schema.tabs;
    let fieldsets = schema.fieldsets;
    let myTab: { id: string, fieldsets: [string] };
    let i = 0;
    let trouve: boolean = false;
    while (!trouve && i < tabs.length) {
      if (tabs[i].id == tabId) {
        myTab = tabs[i];
        trouve = true;
      }
      ++i;
    }
    if (myTab !== undefined) {
      for (let fs of fieldsets) {
        if (myTab.fieldsets.indexOf(fs.id) > -1) {
          results.push(fs);
        }
      }
    }
    return results;
  }

  /*
   * Retourne la liste des valeurs d'une liste de fieldsets
  */
  public static getValuesInFieldsets(fs: any, rootProperty: any): any {
    let value: {} = {};
    for (let localFs of fs) {
      for (let field of localFs.fields) {
        if (rootProperty.properties[field].submitable) {
          value[field] = rootProperty.value[field];
        }
      }
    }
    return value;
  }

  /*
   * Retourne la liste des valeurs du formulaire
  */
  public static getValues(rootProperty: any): any {
    let fs = rootProperty.schema.fieldsets;
    return this.getValuesInFieldsets(fs, rootProperty);
  }

  /*
   * Retourne la liste des valeurs de l'onglet dont l'id est passé en paramètre
  */
  public static getValuesOfTab(tabId: string, rootProperty: any): any {
    let fs = this.getFieldsetsInTab(tabId, rootProperty.schema);
    return this.getValuesInFieldsets(fs, rootProperty);
  }

  /*
   * Indique si toutes les données d'un onglet donné sont valides
  */
  public static isTabValid(tabId: string, rootProperty: any): any {
    let fs = this.getFieldsetsInTab(tabId, rootProperty.schema);
    for (let localFs of fs) {
      for (let field of localFs.fields) {
        //TODO Checker pour les array et les hidden
        if (rootProperty.properties[field].submitable && rootProperty.properties[field].visible
          && !rootProperty.properties[field].valid
          && rootProperty.properties[field].schema.type != 'array'
          && rootProperty.properties[field].schema.widget.id != 'hidden'
          && rootProperty.properties[field] !== rootProperty.properties['city']
          && rootProperty.properties[field] !== rootProperty.properties['bacEtablissement']) {
          return false;
        }
      }
    }
    return true;
  }

  public static areTabsValid(tabIds: Array<string>, rootProperty: any): any {
    for (let i in tabIds) {
      let tabId = tabIds[i];
      if (tabId == 'bourses' && rootProperty.properties['show_bourses'].value == 'O') {
        if (!this.isTabValid(tabId, rootProperty)) {
          return false;
        }
      }
      if (tabId == 'cvec' && rootProperty.properties['show_doc_non_cve'].value == 'N') {
        if (!this.isTabValid(tabId, rootProperty)) {
          return false;
        }
      }
      if (tabId !== 'bourses' && tabId !== 'cvec') {
        if (!this.isTabValid(tabId, rootProperty)) {
          return false;
        }
      }
    }
    return true;
  }

  public static isTabAndPreviousValid(tabId: string, rootProperty: any) {
    // Calcul de la liste des onglets à vérifier
    let tabs = rootProperty.schema.tabs;
    let tabsToCheck = [];
    for (let i = 0; i < tabs.length; i = i + 1) {
      tabsToCheck.push(tabs[i].id);
      if (tabId == tabs[i].id) {
        break;
      }
    }
    return this.areTabsValid(tabsToCheck, rootProperty);
  }

  /*
  Sélectionne un onglet
  */
  public static selectTab(tabId: string, tabs: any): any {
    if (tabId !== undefined) {
      for (let i in tabs) {
        let tab = tabs[i];
        tab.active = (tab.id == tabId);
      }
    }
  }

  public static isAllTabsValid(rootProperty: any): boolean {
    let result = true;
    let tabcand: any = ['choice', 'personal', 'background', 'langs', 'fund', 'docs', 'payment', 'validation'];
    let i: number = 0;
    /*
        for(let tab of rootProperty.schema.tabs) {
      result = result && this.isTabValid(tab.id, rootProperty);
    }
    return result;
    */
    while (i < tabcand.length) {
      result = result && this.isTabValid(tabcand[i], rootProperty);
      ++i;
    }

    return result;
  }

  public static isAllTabsRegValid(rootProperty: any): boolean {
    let resultr = true;
    let tabcandr: any = ['registration', 'funding2', 'parents', 'siblings', 'address', 'documents2', 'register'];
    let i: number = 0;
    /*
        for(let tab of rootProperty.schema.tabs) {
      result = result && this.isTabValid(tab.id, rootProperty);
    }
    return result;
    */
    while (i < tabcandr.length) {
      resultr = resultr && this.isTabValid(tabcandr[i], rootProperty);
      ++i;
    }
    return resultr;
  }

  public static desactivateOnefield(property: any, prop: string) {
    property.properties[prop]['readOnly'] = true;
  }

  public static desactivateAllfields(property: any, tabsId: string[]) {
    let listOfProps: string[] = [];
    let fieldsets: any[] = [];
    tabsId.forEach((tabId) => {
      fieldsets = fieldsets.concat(SchemaUtils.getFieldsetsInTab(tabId, property));
      // if(tabId == "docs") {
      //   this.desactivateArrayFields(property, "docs");
      // }
      // if(tabId == "background") {
      //   this.desactivateArrayFields(property, "heb_line");
      //   this.desactivateArrayFields(property, "prof_xp");
      //   this.desactivateArrayFields(property, "reco_line");
      // }
    });
    fieldsets.forEach((fieldset) => {
      listOfProps = listOfProps.concat(fieldset['fields']);
    });

    for (let prop of listOfProps) {
      property.properties[prop]['readOnly'] = true;
    }
  }

  public static updateOneOf(formProperty, observable) {
    if (observable) {
      observable.subscribe((liste: any) => {
        formProperty.schema.oneOf = liste;
        formProperty.setSchema(formProperty.schema)
      });
    }
  }
}
