<div class="container">

    <div class="jumbotron">
		    <h1>Application and registration</h1>
        <p>This is on-line application system for Master of Science, Advanced Master graduate programs and associated scholarships.<br>
        <BR>
				<a class='btn btn-primary' routerLink='./form/candform' role='button'>Apply &raquo;  </a>		</p>
        <p style="color:black;"><b>- For more info about applications : please contact info-programmes@isae-supaero.fr </b></p> 

  </div>

  <div class="row">
    <div class="col-sm-4">
      <div class="pricing-table-container">
        <div class="panel panel-success">
          <div class="panel-heading text-center" style="min-height: 240px;">
            <h2><span data-toggle="tooltip" data-placement="bottom" title="Master of Science">Masters of Science</span>
            </h2>
            <span data-toggle="tooltip" data-placement="bottom">
								<div class="row">
								<div class="col-sm-12">- Master in Aerospace Engineering (ISAE-SUPAERO)</div>
								</div>
								<div class="row">
									<div class="col-sm-12">- Double degree TUM/ISAE-SUPAERO</div>
								</div>
							</span>
          </div>
        </div>
      </div>
    </div>

    <div class="col-sm-4">
      <div class="pricing-table-container">
        <div class="panel panel-warning">
          <div class="panel-heading text-center" style="min-height: 240px;">
            <h2><span data-toggle="tooltip" data-placement="bottom" title="Advanced Masters">Advanced Masters</span>
            </h2>
            <span data-toggle="tooltip" data-placement="bottom">
							<div class="row">
								<div class="col-sm-12">AES, AIBT, AMS, APM, IEM</div>
							</div>
							<div class="row">
								<div class="col-sm-12">EMS, HADA, SEN, SPA</div>
							</div>
							<div class="row">
								<div class="col-sm-12">SPAPS, TAS-Aero(ADE), TAS-Aero(FTE)</div>
							</div>
							<div class="row">
								<div class="col-sm-12">TAS-Astro</div>
							</div>
						</span>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-4">
      <div class="pricing-table-container">
        <div class="panel panel-danger">
          <div class="panel-heading text-center" style="min-height: 240px;">
            <h2><span data-toggle="tooltip" data-placement="bottom" title="PG DIPLOMA">PG Diploma&nbsp; </span></h2>
            <span data-toggle="tooltip" data-placement="bottom">
							<div class="row">
								<div class="col-sm-12">AES, AMS-E&M, IEM, HADA</div>
							</div>
							<div class="row">
								<div class="col-sm-12">SEN, SPAPS, TAS-Aero(ADE)</div>
							</div>
							<div class="row">
								<div class="col-sm-12">TAS Astro, TAS-Aero(FTE)</div>
							</div>
						</span>
          </div>
        </div>
      </div>
    </div>

  </div>

  <div class="row">
    <div class="col-sm-12">
      <div class="pricing-table-container">
        <div class="panel panel-default">
          <div class="panel-heading text-center" style="min-height: 65px;">
            <a class='form_link5' HREF='https://candidatures.isae.fr/images/CGV-DFM_Candidature_InscriptionISAE_2024.pdf'
               target="_blank"><span class="glyphicon glyphicon-file" aria-hidden="true"></span><b> Download</b><BR>the
              application procedure document</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container">

  <div class="row">
    <div class="col-sm-4">
      <div class="pricing-table-container">
        <!--div class="panel-body "-->
        <ul class="plan-ul">

          <li>
            <strong>Requirements </strong>
            <br/>
            Bachelor degree in engineering or sciences<a href="#"></a> <a
            href='https://www.isae-supaero.fr/en/academics/MSc/isae-supaero-master-s-degree-msc/'>(more details)</a>
          </li>
          <li><strong>Teaching Language</strong> <br/>English</li>
          <li><a href="https://www.isae-supaero.fr/en/academics/MSc/admission/admissions/" target='_blank'>
            <span class="glyphicon glyphicon-info-sign" aria-hidden="true"></span> Admission schedule &
            documents</a><BR>
            <a href="https://www.isae-supaero.fr/en/academics/MSc/financing-144/financing/" target='_blank'>
              <span class="glyphicon glyphicon-info-sign" aria-hidden="true"></span> Scholarship information</a></li>
        </ul>


      </div>
    </div>

    <div class="col-sm-4">
      <div class="pricing-table-container">
        <ul class="plan-ul">
          <li>
            <strong>Requirements </strong>
            <br/>
            Master degree or Bachelor degree + 3 years of professional experience <a
            href='https://www.isae-supaero.fr/en/academics/advanced-masters/presentation/'>(more details)</a>
          </li>
          <li><strong>Teaching Language</strong> <br/>French or English, depending on the program</li>
          <li><a href="https://www.isae-supaero.fr/en/academics/advanced-masters/admissions/" target='_blank'>
            <span class="glyphicon glyphicon-info-sign" aria-hidden="true"></span> Admission schedule &
            documents</a><br>
          </li>
        </ul>
      </div>
    </div>

    <div class="col-sm-4">
      <div class="pricing-table-container">
        <ul class="plan-ul">
          <li>
            <strong>Requirements </strong>
            <br/>
            Master degree or Bachelor degree + 3 years of professional experience <a
            href='https://www.isae-supaero.fr/en/academics/postgraduate-diplomas/postgraduate-diplomas-671/'>(more
            details)</a>
          </li>
          <li><strong>Teaching Language</strong> <br/>French or English, depending on the program</li>
          <li><a href="https://www.isae-supaero.fr/en/academics/advanced-masters/admissions/" target='_blank'>
            <span class="glyphicon glyphicon-info-sign" aria-hidden="true"></span> Admission schedule &
            documents</a><br>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
