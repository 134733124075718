import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';

import {AuthService} from './auth.service';
import {FormSchemaService} from '../form-schema.service';
import {AuthType} from './auth-provider-factory';

@Component({
  selector: 'local-activation',
  templateUrl: './local-activation.component.html'
})
export class LocalActivationComponent implements OnInit {

  login: string = '';
  inpPassword: string = '';
  inpPasswordConf: string = '';
  formid: string = '';
  token: string = '';
  resetPassword: boolean = false;
  activationOK: boolean = false;
  activationNOK: boolean = false;

  constructor(public schemaService: FormSchemaService, public router: Router, private route: ActivatedRoute, private authServ: AuthService) {
  }

  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      this.formid = params['id'];
      this.login = params['user'];
      this.token = params['activationToken'];
      this.resetPassword = params['reset'] == '1' ? true : false;
    });
  }

  isPasswordsEquals(): boolean {
    return this.inpPassword != null && this.inpPassword.trim() != '' && this.inpPassword == this.inpPasswordConf;
  }

  isPasswordValid(): boolean {
    return this.isPasswordsEquals() && this.inpPassword.length >= 12;
  }

  activateUser() {
    if (this.login != undefined && this.login != '' && this.isPasswordValid()) {
      this.authServ.activateUser(this.formid, this.login, this.inpPassword, this.token).subscribe((response: any) => {
        let dt = response;
        if (dt.result == 'ok') {
          this.activationOK = true;
          this.activationNOK = false;
          this.autoLogginAndRedirectToHome();
        } else if (dt.result == 'error') {
          this.activationOK = false;
          this.activationNOK = true;
        }
      });
    }
  }

  private autoLogginAndRedirectToHome() {
    this.authServ.login(AuthType.local, this.login, this.inpPassword).subscribe(() => {
      if (this.authServ.isLoggedIn) {
        // Get the redirect URL from our auth service
        // If no redirect has been set, use the default
        let redirect = this.authServ.redirectUrl ? this.authServ.redirectUrl : '/';
        // Redirect the user
        this.router.navigate(['/']);
      } else {
        this.activationOK = false;
        this.activationNOK = true;
      }
    });
  }

  resetUser() {
    if (this.login != undefined && this.login != '' && this.isPasswordValid()) {
      this.authServ.resetUser(this.formid, this.login, this.inpPassword, this.token).subscribe((response: any) => {
        let dt = response;
        if (dt.result == 'ok') {
          this.activationOK = true;
          this.activationNOK = false;
        } else if (dt.result == 'error') {
          this.activationOK = false;
          this.activationNOK = true;
        }
      });
    }
  }

}
